import { useCallback, useEffect } from "react";

import { useNavigate } from "react-router";

import { useInboundStore } from "flows/Inbound/stores/inboundStore/useInboundStore";
import { useUpdateStockForOutboundReasons } from "flows/Inbound/stores/useUpdateStockForOutboundReasons";
import { isNullOrUndefined } from "utils/tsHelpers";

import { InboundOutboundPage } from "./InboundOutboundPage";

export function OutboundUnclaimedProduct() {
  const navigate = useNavigate();

  const { outboundSku, inboundUnitsMap, inboundUnitsStockUpdates } = useInboundStore((state) => ({
    outboundSku: state.outboundUnclaimedSku,
    inboundUnitsMap: state.inboundUnitsMap,
    inboundUnitsStockUpdates: state.inboundUnitsStockUpdates,
  }));
  const inboundUnitData = inboundUnitsMap[outboundSku || ""];
  const { updateStock, isStockUpdating } = useUpdateStockForOutboundReasons();
  const isInvalidParams = isNullOrUndefined(outboundSku) || isNullOrUndefined(inboundUnitData);

  const returnToInboundHomePage = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    if (isInvalidParams) {
      returnToInboundHomePage();
    }
  }, [isInvalidParams, returnToInboundHomePage]);

  if (isInvalidParams) {
    return null;
  }

  const { shelfLetter, shelfNumber, productImageUrl, productName, productSku } = inboundUnitData;
  const inboundUnitStockUpdatePlan = inboundUnitsStockUpdates[outboundSku || ""]?.stockUpdatePlan;

  return (
    <InboundOutboundPage
      outboundSku={outboundSku}
      shelfLetter={shelfLetter}
      shelfNumber={shelfNumber}
      productSku={productSku}
      productName={productName}
      productImageUrl={productImageUrl}
      stockUpdatePlan={inboundUnitStockUpdatePlan}
      isStockUpdating={isStockUpdating}
      updateStock={updateStock}
      returnToInboundHomePage={returnToInboundHomePage}
    />
  );
}
