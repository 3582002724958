import { useCallback } from "react";

import { datadogLogs } from "@datadog/browser-logs";

import { InventoryChangeReason } from "__graphql__/types";
import {
  CollaborativeInboundProgressedAction,
  CollaborativeInboundProgressedMethod,
  CollaborativeInboundStage,
  CollaborativeInboundUpdateState,
  CollaborativeListPreparationMethod,
  InboundVerificationReason,
} from "analytics/events";
import { ListVerificationCheckTypeKeys } from "flows/Inbound/models/listVerificationCheck/types";
import { DeliveryType, InboundUnitAddOrigin } from "flows/Inbound/stores/inboundStore/types";
import { useInboundStore } from "flows/Inbound/stores/inboundStore/useInboundStore";
import {
  extractInboundPlan,
  sumStockUpdatePlanTotal,
} from "flows/Inbound/stores/inboundStore/utils";
import { useAnalytics } from "shared/hooks/useAnalytics";
import { isNullOrUndefined } from "utils/tsHelpers";

const VERIFICATION_REASON_MAP: Record<ListVerificationCheckTypeKeys, InboundVerificationReason> = {
  [ListVerificationCheckTypeKeys.InboundQuantityOfOne]: "quantity_equal_to_one",
  [ListVerificationCheckTypeKeys.LargeInboundQuantity]: "quantity_more_than_fifty",
  [ListVerificationCheckTypeKeys.HandlingUnitQuantityMismatch]: "quantity_handling_unit_mismatch",
};

export const INBOUND_UNIT_ADD_ORIGIN_MAP: Record<
  InboundUnitAddOrigin,
  CollaborativeInboundProgressedMethod
> = {
  [InboundUnitAddOrigin.Scan]: CollaborativeInboundProgressedMethod.Scanned,
  [InboundUnitAddOrigin.TextSearch]: CollaborativeInboundProgressedMethod.TextSearched,
  [InboundUnitAddOrigin.NoEANSearch]: CollaborativeInboundProgressedMethod.AdvancedSearched,
};

export function useInboundEventTracker() {
  const { sendSegmentTrackEvent } = useAnalytics();
  // Send inbound state update based on specific events
  const sendInboundStateUpdate = useCallback(
    ({
      state,
      droppingListId = null,
      sscc = null,
      inboundingType = null,
      isManualUnclaim = false,
      creatorId = null,
    }: {
      state: CollaborativeInboundUpdateState;
      droppingListId?: string | null;
      sscc?: string | null;
      inboundingType?: DeliveryType | null;
      isManualUnclaim?: boolean;
      creatorId?: string | null;
    }) => {
      if (isNullOrUndefined(droppingListId) || isNullOrUndefined(state)) {
        datadogLogs.logger.info("Missing droppingListId or state in sendInboundStateUpdate");
        return;
      }
      const listPreparationMethod =
        creatorId === "system"
          ? CollaborativeListPreparationMethod.Automatic
          : CollaborativeListPreparationMethod.Manual;
      sendSegmentTrackEvent("inboundStateUpdated", {
        state,
        dropping_list_id: droppingListId,
        sscc,
        inbounding_type: inboundingType,
        is_manual_unclaim: isManualUnclaim,
        list_preparation_method: listPreparationMethod,
      });
    },
    [sendSegmentTrackEvent],
  );

  // Send progress update for inbound actions
  const sendInboundProgressed = useCallback(
    ({
      sku,
      action,
      method,
      quantityInfo,
    }: {
      sku: string;
      action: CollaborativeInboundProgressedAction;
      method?: CollaborativeInboundProgressedMethod;
      quantityInfo?: { newValue?: number; oldValue?: number };
    }) => {
      const {
        deliverySSCC,
        sharedListId,
        sharedListName,
        inboundUnitsStockUpdates,
        inboundUnitsDisplayStates,
      } = useInboundStore.getState();

      if (isNullOrUndefined(sharedListId)) {
        datadogLogs.logger.info("Missing sharedListId in sendInboundProgressed action");
        return;
      }

      if (isNullOrUndefined(sku)) {
        datadogLogs.logger.info("Missing SKU in sendInboundProgressed action");
        return;
      }

      const inboundUnitStockUpdateState = inboundUnitsStockUpdates[sku];
      const currentUnitSize = inboundUnitsDisplayStates[sku]?.unitSizeForDisplay || 0;

      let quantity = 0;
      let temporaryHandlingUnitSize: number | undefined;

      switch (action) {
        case CollaborativeInboundProgressedAction.ProductAddedToList:
          quantity = currentUnitSize;
          break;

        case CollaborativeInboundProgressedAction.ProductRemovedFromList:
          quantity = sumStockUpdatePlanTotal(inboundUnitStockUpdateState.stockUpdatePlan);
          break;

        case CollaborativeInboundProgressedAction.ProductUpdatedQuantity:
          if (quantityInfo) {
            const { newValue = 0, oldValue = 0 } = quantityInfo;
            if (method === CollaborativeInboundProgressedMethod.ManualSU) {
              quantity = (newValue - oldValue) * currentUnitSize;
            } else if (method === CollaborativeInboundProgressedMethod.ManualHU) {
              quantity = newValue - oldValue;
              temporaryHandlingUnitSize = newValue;
            }
          }
          break;
        default:
          return;
      }

      sendSegmentTrackEvent("inboundProgressed", {
        dropping_list_id: sharedListId,
        product_sku: sku,
        action,
        is_handling_unit: currentUnitSize !== 1,
        method,
        quantity,
        sscc: deliverySSCC,
        shelf_number: null,
        temporary_handling_unit_size: temporaryHandlingUnitSize,
        dropping_list_name: sharedListName,
        inbounding_stage: CollaborativeInboundStage.ListPreparation,
      });
    },
    [sendSegmentTrackEvent],
  );

  // Track verification progress
  const sendInboundVerificationUpdate = useCallback(
    ({ checkKey, currentCheckIndex }: { checkKey: string; currentCheckIndex: number }) => {
      const { deliverySSCC, sharedListId, verificationChecks, inboundUnitsStockUpdates } =
        useInboundStore.getState();
      if (isNullOrUndefined(sharedListId)) {
        datadogLogs.logger.info("Missing droppingListId in sendInboundVerificationUpdate");
        return;
      }

      const check = verificationChecks.find((c) => c.key === checkKey);
      if (isNullOrUndefined(check)) {
        datadogLogs.logger.info(
          `Cannot find check with key ${checkKey} in sendInboundVerificationUpdate`,
        );
        return;
      }

      const inboundStockUpdate = extractInboundPlan(
        inboundUnitsStockUpdates[check.sku]?.stockUpdatePlan,
      );

      if (isNullOrUndefined(inboundStockUpdate)) {
        datadogLogs.logger.info(
          `Missing stock update for SKU ${check.sku} in sendInboundVerificationUpdate`,
        );
        return;
      }

      const quantityBeforeVerification =
        check.type === ListVerificationCheckTypeKeys.InboundQuantityOfOne ? 1 : check.data.quantity;

      sendSegmentTrackEvent("inboundVerificationProgressed", {
        dropping_list_id: sharedListId,
        sscc: deliverySSCC,
        product_sku: check.sku,
        verification_reason: VERIFICATION_REASON_MAP[check.type],
        quantity_before_verification: quantityBeforeVerification,
        quantity_after_verification: Math.max(inboundStockUpdate.quantityDelta, 0),
        running_number: currentCheckIndex + 1,
      });
    },
    [sendSegmentTrackEvent],
  );

  // Track outbound progress
  const sendOutboundUpdate = useCallback(
    ({
      sku,
      updatedStockUpdatePlan,
      shelf,
    }: {
      sku: string;
      updatedStockUpdatePlan: {
        quantityDelta: number;
        reason: InventoryChangeReason;
      }[];
      shelf: string | null;
    }) => {
      const { deliverySSCC, sharedListId } = useInboundStore.getState();

      if (isNullOrUndefined(sharedListId)) {
        datadogLogs.logger.info("Missing sharedListId in sendOutboundProgressed action");
        return;
      }

      const { quantityExpired, quantityDamaged, quantityInbounded } = updatedStockUpdatePlan.reduce(
        (acc, { reason, quantityDelta }) => {
          switch (reason) {
            case InventoryChangeReason.outbound_delivery_product_expired:
              acc.quantityExpired += quantityDelta;
              break;
            case InventoryChangeReason.outbound_delivery_product_damaged:
              acc.quantityDamaged += quantityDelta;
              break;
            case InventoryChangeReason.inbound_goods_received:
              acc.quantityInbounded += quantityDelta;
              break;
            default:
              break;
          }
          return acc;
        },
        { quantityExpired: 0, quantityDamaged: 0, quantityInbounded: 0 },
      );

      sendSegmentTrackEvent("outboundProgressed", {
        action: "product_outbounded",
        quantity_expired: quantityExpired,
        quantity_damaged: quantityDamaged,
        quantity_perished: 0,
        quantity_received: quantityInbounded,
        dropping_list_id: sharedListId,
        product_sku: sku,
        sscc: deliverySSCC,
        shelf_number: shelf,
      });
    },
    [sendSegmentTrackEvent],
  );

  return {
    sendInboundStateUpdate,
    sendInboundProgressed,
    sendInboundVerificationUpdate,
    sendOutboundUpdate,
  };
}
