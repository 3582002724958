import { useCallback } from "react";

import { Flex } from "@chakra-ui/react";
import { useNavigate } from "react-router";
import shallow from "zustand/shallow";

import { routes } from "config/routes";
import { ProductsSearchComponent } from "flows/Inbound/components/ProductsSearchComponent";
import { Page } from "shared/components/Page";
import {
  ProductSearchActionTypes,
  useProductSearchStore,
} from "shared/stores/useProductSearchStore";

import { CategoryProductSearch } from "../components/CategoryProductSearch";
import { TextSearchProductList } from "../components/TextSearchProductList";

export function TGTGSelectProductPage() {
  const navigate = useNavigate();

  const { textSearch, activeState, setActivateTextSearch, resetTextSearch } = useProductSearchStore(
    (state) => ({
      textSearch: state.textSearch,
      activeState: state.activeState,
      setActivateTextSearch: state.setActivateTextSearch,
      resetTextSearch: state.resetTextSearch,
    }),
    shallow,
  );

  const activateTextSearch = useCallback(() => {
    setActivateTextSearch();
  }, [setActivateTextSearch]);

  const cancelSearchFlow = useCallback(() => {
    resetTextSearch();
    navigate(routes.inventory.tooGoodToGo.prepareBag);
  }, [navigate, resetTextSearch]);

  const shouldShowTextSearchResults =
    textSearch && activeState === ProductSearchActionTypes.RESOLVE_TEXT_SEARCH;

  return (
    <Page h="100%" pos="relative" isFull isBgGrey data-testid="inbound-manual-search-page">
      <Flex direction="column" justify="flex-start" h="100%" width="100%">
        <ProductsSearchComponent
          isSearchFlowActive
          activateTextSearch={activateTextSearch}
          cancelSearchFlow={cancelSearchFlow}
          showUnitsToggle={false}
        />
        {shouldShowTextSearchResults ? <TextSearchProductList /> : <CategoryProductSearch />}
      </Flex>
    </Page>
  );
}
