import { useCallback, memo, useRef, useState, useEffect } from "react";

import { Box } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";

import { routes } from "config/routes";
import { useLayoutStore } from "core/stores/useLayoutStore";
import { EppoFeatureFlags } from "core/types/flags";
import { useEppoFeatureFlagProvider } from "shared/hooks/useEppoFeatureFlag";
import useSwipeScroll from "shared/hooks/useSwipeScroll";
import { OutboundIcon, PenIcon, TrashIcon } from "ui/Icons/Icons";
import {
  InboundDroppingListItemCard,
  InboundDroppingListItemCardTranslatedLabelsProps,
} from "ui/InboundDroppingListItemCard/InboundDroppingListItemCard";
import { SideButton } from "ui/SideButton/SideButton";
import { SwipeScrollWrapper } from "ui/SwipeScrollWrapper";

import { useInboundStore } from "../stores/inboundStore/useInboundStore";
import {
  useDeleteClaimedProduct,
  useEditClaimedProduct,
  useInboundClaimedProduct,
} from "../stores/useInboundDropService";

type InboundDroppingListItemProps = {
  sku: string;
  onClickCard?: () => void;
  handleTapOnCardBanner: () => void;
  handleUnforcus: () => void;
  translatedLabels: InboundDroppingListItemCardTranslatedLabelsProps;
  elementRef: React.RefObject<HTMLDivElement>;
};

function InboundDroppingListItemComponent({
  sku,
  translatedLabels,
  elementRef,
  handleTapOnCardBanner,
  handleUnforcus,
}: InboundDroppingListItemProps) {
  const intl = useIntl();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const scrollContainerRef = useLayoutStore((state) => state.scrollContainerRef);
  const { handleSwipeScroll, snapWrapperBack } = useSwipeScroll(scrollContainerRef, wrapperRef);
  const navigate = useNavigate();

  const {
    droppingListId,
    droppingListName,
    claimedProductsMap,
    claimedProductsStockUpdates,
    inboundUIState,
    setOutboundClaimedSku,
    setInboundUIState,
  } = useInboundStore((state) => ({
    droppingListId: state.droppingListId,
    droppingListName: state.droppingListName,
    claimedProductsMap: state.claimedProductsMap,
    claimedProductsStockUpdates: state.claimedProductsStockUpdates,
    inboundUIState: state.inboundUIState,
    setOutboundClaimedSku: state.setOutboundClaimedSku,
    setInboundUIState: state.setInboundUIState,
  }));
  const { isClaimedQuantityEditActiveForSku } = inboundUIState;
  const {
    unitSizeForDisplay,
    totalInboundQuantity,
    handlingUnitSize,
    productId,
    shelf,
    ...claimedProducts
  } = claimedProductsMap[sku];
  const { stockUpdated } = claimedProductsStockUpdates[sku];
  const { inboundClaimedProduct, isInboundingProduct } = useInboundClaimedProduct();
  const { deleteClaimedProduct, isDeletingProduct } = useDeleteClaimedProduct();
  const { editInboundProduct, isEditingProduct } = useEditClaimedProduct();
  const onClickCard = useCallback(() => {
    if (isInboundingProduct || stockUpdated || isEditingProduct) {
      return;
    }
    handleTapOnCardBanner();
    handleUnforcus();
    inboundClaimedProduct(sku, productId);
  }, [
    isInboundingProduct,
    stockUpdated,
    isEditingProduct,
    handleTapOnCardBanner,
    handleUnforcus,
    inboundClaimedProduct,
    sku,
    productId,
  ]);

  const isOnlyUnstockedSku = Object.keys(claimedProductsStockUpdates).length === 1 && !stockUpdated;

  const { isFeatureEnabled: isProductFacingEnabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.PRODUCT_FACING,
  );

  const onClickRemoveProduct = () => {
    if (isInboundingProduct || stockUpdated || isDeletingProduct) {
      return;
    }
    if (isOnlyUnstockedSku) {
      setInboundUIState({ isDeleteFinalItemModalVisibleForSku: sku });
    } else {
      deleteClaimedProduct(droppingListId!, sku);
    }
    snapWrapperBack();
  };

  const onClickEditProductQuantity = () => {
    if (isEditingProduct) return;
    setInboundUIState({ isClaimedQuantityEditActiveForSku: sku });
    snapWrapperBack();
  };

  const [newQuantity, setNewQuantity] = useState(unitSizeForDisplay);
  useEffect(() => {
    if (!isEditingProduct) {
      setNewQuantity(unitSizeForDisplay);
    }
  }, [isEditingProduct, unitSizeForDisplay]);
  const handleQuantityOnChange = useCallback((newValue: number) => {
    setNewQuantity(newValue);
  }, []);

  const handleQuantitySubmit = useCallback(
    (newValue: number) => {
      setInboundUIState({ isClaimedQuantityEditActiveForSku: null });
      if (newValue === unitSizeForDisplay) return;
      if (!droppingListId || !droppingListName || isEditingProduct || newValue < 1) return;
      editInboundProduct({
        droppingListId,
        droppingListName,
        sku,
        shelf,
        newValue,
        oldValue: unitSizeForDisplay,
      });
    },
    [
      sku,
      unitSizeForDisplay,
      droppingListId,
      droppingListName,
      shelf,
      isEditingProduct,
      setInboundUIState,
      editInboundProduct,
    ],
  );

  const createFieldProps = useCallback(
    (value: number, onSubmit: (value: number) => void) => ({
      inputProps: {
        value,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          const currentValue = Number(e.target.value);
          handleQuantityOnChange(currentValue);
        },
        onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
          const currentValue = Number(e.target.value);
          onSubmit(currentValue);
        },
      },
      onSubmitForm: (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit(value);
      },
    }),
    [handleQuantityOnChange],
  );

  const quantityFieldProps = createFieldProps(newQuantity, handleQuantitySubmit);

  const onClickOutboundButton = useCallback(() => {
    setOutboundClaimedSku(sku);
    navigate(routes.inbound.outboundClaimed);
  }, [navigate, setOutboundClaimedSku, sku]);

  const mainComponent = (
    <InboundDroppingListItemCard
      stockUpdatePlanTotal={totalInboundQuantity}
      onClickCard={onClickCard}
      ref={elementRef}
      displayQuantity={unitSizeForDisplay}
      isStockUpdateComplete={stockUpdated}
      displayAsHandlingUnit={handlingUnitSize > 1}
      shouldShowProductFacing={!!isProductFacingEnabled}
      isInEditMode={isClaimedQuantityEditActiveForSku === sku}
      quantityFieldProps={quantityFieldProps}
      {...translatedLabels}
      {...claimedProducts}
    />
  );

  const scrolledComponent = (
    <Box flex="0 0 137px">
      <SideButton
        color="red.500"
        bgColor="red.100"
        icon={<TrashIcon boxSize={6} />}
        label={intl.formatMessage({
          id: "components.inbound.dropping-list-item.delete-label",
          defaultMessage: "Delete",
        })}
        onClick={onClickRemoveProduct}
        data-testid="remove-product"
        height="33%"
      />
      <SideButton
        color="veggie.500"
        bgColor="veggie.200"
        icon={<PenIcon boxSize={6} />}
        label={intl.formatMessage({
          id: "components.inbound.dropping-list-item.edit-quantity-label",
          defaultMessage: "Edit",
        })}
        onClick={onClickEditProductQuantity}
        data-testid="edit-product-quantity"
        height="33%"
      />
      <SideButton
        color="purple.500"
        bgColor="purple.100"
        icon={<OutboundIcon boxSize={6} />}
        label={intl.formatMessage({
          id: "components.inbound.dropping-list-item.outbound-label",
          defaultMessage: "Outbound",
        })}
        onClick={onClickOutboundButton}
        data-testid="go-to-inbound-outbound-page"
        height="33%"
      />
    </Box>
  );

  return (
    <SwipeScrollWrapper
      mainComponent={mainComponent}
      handleSwipeScroll={handleSwipeScroll}
      scrolledComponent={scrolledComponent}
      showSwipeButtons={!stockUpdated}
      ref={wrapperRef}
      flex="none"
    />
  );
}

export const InboundDroppingListItem = memo(InboundDroppingListItemComponent);

InboundDroppingListItem.displayName = "InboundDroppingListItem";
