import { useCallback } from "react";

import { Flex, useDisclosure } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { unstable_useBlocker as useBlocker } from "react-router-dom";

import { routes } from "config/routes";
import { DiscardBagConfirmationModal } from "flows/Inbound/components/DiscardBagConfirmationModal";
import { ProductsSearchComponent } from "flows/Inbound/components/ProductsSearchComponent";
import { ScanningFailedModal } from "flows/Inbound/components/ScanningFailedModal";
import { ScanPlaceholder } from "flows/Inventory/shared/components/ScanPlaceholder";
import { Page } from "shared/components/Page";
import { SpinnerModal } from "shared/components/SpinnerModal";
import { useScan } from "shared/hooks/useScan/useScan";
import {
  ProductSearchActionTypes,
  useProductSearchStore,
} from "shared/stores/useProductSearchStore";
import { IntlMessageId } from "shared/types/lang";
import { NavigationHeader } from "ui/NavigationHeader/NavigationHeader";

import { EndPreparationButton } from "../components/EndPreparationButton";
import { ProductInfoCard } from "../components/ProductInfoCard";
import { useFetchProductsByEanWithPrice } from "../hooks/useFetchProductsByEan";
import { useTGTGBagStore } from "../stores/useTGTGBagStore";

export function TGTGPrepareBagPage() {
  const navigate = useNavigate();
  const intl = useIntl();
  const {
    isOpen: isDiscardBagConfirmationModalOpen,
    onOpen: openDiscardBagConfirmationModal,
    onClose: closeDiscardBagConfirmationModal,
  } = useDisclosure();
  const {
    isOpen: isScanningFailedModalOpen,
    onOpen: openScanningFailedModal,
    onClose: closeScanningFailedModal,
  } = useDisclosure();
  const { bagKey, price, totalPrice, products, reset } = useTGTGBagStore();
  const { onScan, isScannedProductLoading } = useFetchProductsByEanWithPrice({
    openScanningFailedModal,
  });
  const setSearchState = useProductSearchStore((state) => state.setSearchState);

  const blockerFunction = (args: any) =>
    !args.nextLocation.pathname.includes("/inventory/too-good-to-go");
  const blocker = useBlocker(blockerFunction);
  const handleConfirmNavigation = () => {
    if (isDiscardBagConfirmationModalOpen) {
      closeDiscardBagConfirmationModal();
      navigate(routes.inventory.tooGoodToGo.root);
    } else {
      blocker.proceed?.();
    }
    reset();
  };
  const handleCancelNavigation = () => {
    blocker.reset?.();
    closeDiscardBagConfirmationModal();
  };
  const activateSearchFlow = useCallback(() => {
    setSearchState(ProductSearchActionTypes.ACTIVATE_SEARCH);
    navigate(routes.inventory.tooGoodToGo.selectProduct);
  }, [navigate, setSearchState]);

  useScan({ onScan });

  return (
    <Page isFull isBgGrey h="100%" justifyContent="flex-start">
      <NavigationHeader
        title={intl.formatMessage({
          id: `pages.tgtg-bag-selection-page.bag-types.${bagKey}` as IntlMessageId,
        })}
        onClickGoBack={openDiscardBagConfirmationModal}
      />
      <ProductsSearchComponent
        isSearchFlowActive={false}
        activateTextSearch={activateSearchFlow}
        showUnitsToggle={false}
      />
      {!products.length ? (
        <ScanPlaceholder
          title="pages.tgtg-bag-selection-page.scan-placeholder.title"
          subtitle="pages.tgtg-bag-selection-page.scan-placeholder.subtitle"
          justifyContent="center"
          flexGrow="1"
          px="s400"
        />
      ) : (
        <Flex
          direction="column"
          w="100%"
          p="s100"
          marginBottom="72px"
          gap="s200"
          pb="s300"
          overflow="scroll"
          css={{
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {products.map((productSku: string) => (
            <ProductInfoCard productSku={productSku} />
          ))}
        </Flex>
      )}
      <EndPreparationButton
        bagOfPrice={price ?? 0}
        totalPrice={totalPrice}
        onClickButton={() => navigate(routes.inventory.tooGoodToGo.confirmation)}
      />
      <DiscardBagConfirmationModal
        isOpen={blocker.state === "blocked" || isDiscardBagConfirmationModalOpen}
        onClickYes={handleConfirmNavigation}
        onClickNo={handleCancelNavigation}
      />
      <ScanningFailedModal
        isOpen={isScanningFailedModalOpen}
        onClickConfirm={closeScanningFailedModal}
      />
      <SpinnerModal isOpen={isScannedProductLoading} />
    </Page>
  );
}
