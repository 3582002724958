import { useCallback, useEffect, useMemo } from "react";

import { Flex, Menu, MenuButton, MenuList, MenuItem, IconButton, Box } from "@chakra-ui/react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router";
import shallow from "zustand/shallow";

import { ListProductStatus } from "__graphql__/types";
import { routes } from "config/routes";
import { useHandleSharedListNameUpdate } from "core/hooks/useHandleSharedListNameUpdate";
import { EndDroppingProcessButton } from "flows/Inbound/components/EndDroppingProcessButton";
import { useSerialisedDespatchAdvice } from "flows/Inbound/hooks/useDespatchAdvice/useDespatchAdvice";
import { TranslatedProduct } from "flows/Inbound/stores/inboundStore/types";
import { useInboundStore } from "flows/Inbound/stores/inboundStore/useInboundStore";
import { useClaimInboundList } from "flows/Inbound/stores/useInboundDropService";
import { useInboundPreviewService } from "flows/Inbound/stores/useInboundPreviewService";
import { Page } from "shared/components/Page";
import { SpinnerModal } from "shared/components/SpinnerModal";
import { BOTTOM_TAB_BAR_HEIGHT_NUMBER } from "ui/BottomTabBarContainer";
import { CheckIcon, FilledPencilIcon, NewProfileIcon, ThreedotsIcon } from "ui/Icons/Icons";
import { InboundPreDroppingListItemCard } from "ui/InboundPreDroppingListItemCard/InboundPreDroppingListItemCard";
import { NavigationHeaderWithInput } from "ui/NavigationHeaderWithInput/NavigationHeaderWithInput";

export function InboundPreviewProductCard({ product }: { product: Readonly<TranslatedProduct> }) {
  const intl = useIntl();
  const translatedLabels = useMemo(
    () => ({
      singleUnitsLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.single-units-label",
      }),
      handlingUnitsLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.handling-units-label",
      }),
      inboundedLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.inbounded-label",
      }),
      totalUnitsLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.total-handling-units-label",
      }),
      bioLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.bio-label",
      }),
      nonBioLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.non-bio-label",
      }),
      partialOutboundLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.partial-outbound-label",
      }),
      outboundedLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.outbounded-label",
      }),
      outboundLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.outbound-label",
      }),
    }),
    [intl],
  );
  const {
    totalInboundQuantity,
    handlingUnitSize,
    unitSizeForDisplay,
    status,
    ...translatedInboundUnit
  } = product;
  const { productSku } = translatedInboundUnit;
  const despatchAdviceItems = useInboundStore((state) => state.despatchAdviceItems);
  const despatchAdviceItem = useSerialisedDespatchAdvice({
    sku: productSku,
    despatchAdviceItems,
    inboundQuantity: totalInboundQuantity,
  });

  return (
    <InboundPreDroppingListItemCard
      key={productSku}
      {...translatedLabels}
      translatedInboundUnit={translatedInboundUnit}
      displayQuantity={unitSizeForDisplay}
      displayAsHandlingUnit={handlingUnitSize > 1}
      stockUpdatePlanTotal={totalInboundQuantity}
      showExpectedItems={!!despatchAdviceItem}
      expectedUnitType={despatchAdviceItem?.expectedUnitType}
      expectedNumberOfUnits={despatchAdviceItem?.expectedNumberOfUnits}
      isQuantityMatchingExpectation={false}
      isStockUpdateComplete={status === ListProductStatus.list_product_status_inbounded}
      isEnabledFilter={false}
    />
  );
}

export function InboundPreviewProductsPage() {
  const navigate = useNavigate();
  const { claimInboundList } = useClaimInboundList();
  const { isFetchingProducts } = useInboundPreviewService();
  const { newListName, setNewListName, handleUpdateSharedListName } =
    useHandleSharedListNameUpdate();

  const {
    sharedListId,
    sharedListName,
    inboundPreviewProducts,
    inboundUIState,
    setInboundUIState,
    resetToInitialState,
  } = useInboundStore(
    (state) => ({
      sharedListName: state.sharedListName,
      sharedListId: state.sharedListId,
      inboundPreviewProducts: state.inboundPreviewProducts,
      inboundUnitsSortedByDate: state.inboundUnitsSortedByDate,
      inboundUIState: state.inboundUIState,
      setInboundUIState: state.setInboundUIState,
      resetToInitialState: state.resetToInitialState,
    }),
    shallow,
  );
  const { isSharedListNameEditActive } = inboundUIState;
  const handleClaimInboundListClick = useCallback(() => {
    if (sharedListId) {
      claimInboundList(sharedListId);
    }
  }, [sharedListId, claimInboundList]);

  useEffect(() => {
    if (!sharedListName) navigate(routes.inbound.root);
  }, [navigate, sharedListName]);

  return (
    <Page isFull isBgGrey h="100%">
      <NavigationHeaderWithInput
        title={newListName}
        onClickGoBack={() => {
          resetToInitialState();
          navigate(routes.inbound.root);
        }}
        onTitleChange={(value) => {
          setNewListName(value);
        }}
        isDisabled={!isSharedListNameEditActive}
        rightElement={
          isSharedListNameEditActive ? (
            <CheckIcon
              width="24px"
              height="24px"
              cursor="pointer"
              onClick={handleUpdateSharedListName}
            />
          ) : (
            <Menu>
              <MenuButton
                as={IconButton}
                icon={<ThreedotsIcon boxSize="24px" />}
                variant="unstyled"
                aria-label="Options"
                w="24px"
                h="24px"
                textAlign="end"
              />
              <MenuList
                zIndex={50}
                minWidth="140px"
                borderRadius="sm"
                width="auto"
                mt="-45px"
                boxShadow="lg"
              >
                <MenuItem
                  icon={<NewProfileIcon boxSize={4} />}
                  sx={{
                    _hover: {
                      bg: "transparent",
                    },
                  }}
                  onClick={handleClaimInboundListClick}
                  paddingY="s150"
                >
                  <FormattedMessage id="flows.inbound.components.shared-list-component.menu-claim" />
                </MenuItem>
                <MenuItem
                  icon={<FilledPencilIcon boxSize={4} />}
                  sx={{
                    _hover: {
                      bg: "transparent",
                    },
                  }}
                  paddingY="s150"
                  onClick={() => {
                    setInboundUIState({ isSharedListNameEditActive: true });
                  }}
                >
                  <FormattedMessage id="flows.inbound.components.shared-list-component.menu-edit" />
                </MenuItem>
              </MenuList>
            </Menu>
          )
        }
      />
      <Flex
        direction="column"
        pos="relative"
        gap="s150"
        flex={1}
        overflowY="scroll"
        w="100%"
        pt="s200"
        onClick={() => {
          // TO_DO
        }}
      >
        {inboundPreviewProducts?.length > 0 &&
          inboundPreviewProducts.map((product) => (
            <InboundPreviewProductCard key={product.productSku} product={product} />
          ))}
        <Box mb="60px" />
        <EndDroppingProcessButton
          onClickButton={handleClaimInboundListClick}
          labelMessageId="flows.inbound.components.shared-list-component.claim-button"
          bottom={BOTTOM_TAB_BAR_HEIGHT_NUMBER}
        />
      </Flex>
      <SpinnerModal isOpen={isFetchingProducts} />
    </Page>
  );
}
