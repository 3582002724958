import { useCallback, useState } from "react";

import { BackgroundProps, Box, ColorProps, Flex } from "@chakra-ui/react";
import { FormattedMessage, useIntl } from "react-intl";

import { InventoryChangeReason } from "__graphql__/types";
import { OutboundItemRemovalWarningModal } from "flows/Inbound/components/OutboundItemRemovalWarningModal";
import { StockUpdatePlan } from "flows/Inbound/stores/inboundStore/types";
import { useInboundStore } from "flows/Inbound/stores/inboundStore/useInboundStore";
import { IncreaseDecrease } from "shared/components/IncreaseDecrease";
import { Page } from "shared/components/Page";
import { BottomTabBarContainer } from "ui/BottomTabBarContainer";
import { Button } from "ui/Button/Button";
import { NavigationHeaderShelfNumber } from "ui/NavigationHeaderShelfNumber/NavigationHeaderShelfNumber";
import { ProductInformation } from "ui/ProductInformation/ProductInformation";
import { HeaderM, TitleS } from "ui/Typography/Typography";

type OutboundCounterProps = {
  numberColor?: ColorProps["color"];
  bgColor?: BackgroundProps["bg"];
  value: number;
  title: string;
  countDataTestId?: string;
};

type InboundOutboundPageProps = {
  outboundSku: string;
  shelfLetter: string | null;
  shelfNumber: string | null;
  productSku: string;
  productName: string | null;
  productImageUrl: string | null;
  stockUpdatePlan: StockUpdatePlan[];
  isStockUpdating: boolean;
  updateStock: (sku: string, quantitiesByReason: StockUpdatePlan[]) => void;
  returnToInboundHomePage: () => void;
};

function OutboundCounter({
  numberColor,
  bgColor,
  value,
  title,
  countDataTestId,
}: OutboundCounterProps) {
  return (
    <Flex direction="column" alignItems="center">
      <TitleS color="grey.600">{title}</TitleS>
      <HeaderM
        data-testid={countDataTestId}
        paddingX="s100"
        borderRadius="8px"
        bg={bgColor}
        color={numberColor}
        mt="s50"
      >
        {value}
      </HeaderM>
    </Flex>
  );
}

export function InboundOutboundPage({
  outboundSku,
  shelfLetter,
  shelfNumber,
  productSku,
  productName,
  productImageUrl,
  stockUpdatePlan,
  isStockUpdating,
  updateStock,
  returnToInboundHomePage,
}: InboundOutboundPageProps) {
  const intl = useIntl();
  const { inboundUIState, setInboundUIState } = useInboundStore((state) => ({
    inboundUIState: state.inboundUIState,
    setInboundUIState: state.setInboundUIState,
  }));

  const initialCountForReason = useCallback(
    (reason: InventoryChangeReason) => {
      return stockUpdatePlan?.find((plan: any) => plan.reason === reason)?.quantityDelta ?? 0;
    },
    [stockUpdatePlan],
  );

  const [damagedCount, setDamagedCount] = useState(
    () => -initialCountForReason(InventoryChangeReason.outbound_delivery_product_damaged),
  );
  const [expiredCount, setExpiredCount] = useState(
    () => -initialCountForReason(InventoryChangeReason.outbound_delivery_product_expired),
  );
  const inboundCount = initialCountForReason(InventoryChangeReason.inbound_goods_received);

  const outboundedCount = expiredCount + damagedCount;
  const isIncreaseBtnDisabled = outboundedCount >= inboundCount;
  const newStockValue = inboundCount - outboundedCount;

  const confirmOutboundChoices = useCallback(() => {
    const quantitiesByReason = [
      {
        reason: InventoryChangeReason.outbound_delivery_product_damaged,
        quantityDelta: -damagedCount,
      },
      {
        reason: InventoryChangeReason.outbound_delivery_product_expired,
        quantityDelta: -expiredCount,
      },
    ];

    const outboundTotal = quantitiesByReason.reduce((sum, { quantityDelta }) => {
      return sum + Math.abs(quantityDelta);
    }, 0);

    const shouldShowPartiallyOutboundedAnimation =
      quantitiesByReason.every(({ reason }) => initialCountForReason(reason) === 0) &&
      quantitiesByReason.some(({ quantityDelta }) => quantityDelta < 0) &&
      outboundTotal < initialCountForReason(InventoryChangeReason.inbound_goods_received);

    setInboundUIState({
      isPartialOutboundAnimationVisible: shouldShowPartiallyOutboundedAnimation
        ? outboundSku
        : null,
      isOutboundItemRemovalWarningModalVisible: false,
    });
    if (outboundTotal > 0) updateStock(outboundSku || "", quantitiesByReason);
    returnToInboundHomePage();
  }, [
    damagedCount,
    expiredCount,
    initialCountForReason,
    outboundSku,
    returnToInboundHomePage,
    setInboundUIState,
    updateStock,
  ]);

  const handleOnConfirm = useCallback(() => {
    if (newStockValue === 0) {
      setInboundUIState({ isOutboundItemRemovalWarningModalVisible: true });
    } else {
      confirmOutboundChoices();
    }
  }, [confirmOutboundChoices, newStockValue, setInboundUIState]);

  return (
    <Page isFull isBgGrey h="100%">
      <NavigationHeaderShelfNumber
        title={intl.formatMessage({ id: "pages.inbound.inbound-outbound.title" })}
        shelfLetter={shelfLetter}
        shelfNumber={shelfNumber}
        onClickGoBack={returnToInboundHomePage}
      />
      <Flex
        direction="column"
        w="100%"
        h="100%"
        flex={1}
        justify="flex-start"
        bg="grey.100"
        overflowX="scroll"
      >
        <Box bg="white" w="full">
          <ProductInformation
            name={productName ?? ""}
            imageUrl={productImageUrl}
            sku={productSku}
            variantSize="s"
            p="s200"
            borderRadius="0"
          />
        </Box>
        <Flex direction="column" w="100%" p="s200" gap="s200">
          <Flex
            alignItems="center"
            justifyContent="space-evenly"
            borderRadius="8px"
            bg="white"
            w="100%"
            p="s200"
          >
            <OutboundCounter
              countDataTestId="counted-value"
              title={intl.formatMessage({ id: "pages.inbound.inbound-outbound.counted-title" })}
              value={inboundCount}
            />
            <OutboundCounter
              countDataTestId="outbounded-value"
              title={intl.formatMessage({
                id: "pages.inbound.inbound-outbound.outbounded-title",
              })}
              value={outboundedCount * -1}
              numberColor="purple.500"
            />
            <OutboundCounter
              countDataTestId="new-stock-value"
              title={intl.formatMessage({ id: "pages.inbound.inbound-outbound.new-stock-title" })}
              value={newStockValue}
              numberColor="pinkFlink.500"
              bgColor="pinkFlink.100"
            />
          </Flex>
          <Flex direction="column" gap="s50">
            <Box bg="white" borderRadius="8px" p="s200">
              <IncreaseDecrease
                dataTestId="damaged"
                changeHandler={setDamagedCount}
                labelMessageId="pages.inbound.inbound-outbound.damaged-adjuster-label"
                count={damagedCount}
                isIncreaseBtnDisabled={isIncreaseBtnDisabled}
              />
            </Box>
            <Box bg="white" borderRadius="8px" p="s200">
              <IncreaseDecrease
                dataTestId="expired"
                changeHandler={setExpiredCount}
                labelMessageId="pages.inbound.inbound-outbound.expired-adjuster-label"
                count={expiredCount}
                isIncreaseBtnDisabled={isIncreaseBtnDisabled}
              />
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <BottomTabBarContainer
        w="100%"
        px="s200"
        bgColor="white"
        borderTopWidth="0.5px"
        borderTopColor="grey.200"
        align="center"
      >
        <Button
          w="100%"
          h="48px"
          onClick={handleOnConfirm}
          isLoading={isStockUpdating}
          data-testid="outbound-confirm"
        >
          <FormattedMessage id="pages.inbound.inbound-outbound.confirm-button" />
        </Button>
      </BottomTabBarContainer>
      <OutboundItemRemovalWarningModal
        isOpen={inboundUIState.isOutboundItemRemovalWarningModalVisible}
        onClickYes={confirmOutboundChoices}
        onClickNo={() => setInboundUIState({ isOutboundItemRemovalWarningModalVisible: false })}
      />
    </Page>
  );
}
