import { useRef, useState } from "react";

import { FlexProps, InputProps } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

import { IntlMessageId } from "shared/types/lang";
import { IncreaseDecrease as IncreaseDecreaseUI } from "ui/IncreaseDecrease/IncreaseDecrease";
import { transformChunks } from "utils/ui";

export type IncreaseDecreaseProps = {
  labelMessageId: IntlMessageId;
  count?: number;
  changeHandler: (count: number) => void;
  withInput?: boolean;
  isIncreaseBtnDisabled?: boolean;
  isDecreaseBtnDisabled?: boolean;
  allowNevativeInput?: boolean;
  dataTestId?: string;
} & FlexProps;

export function IncreaseDecrease({
  changeHandler,
  labelMessageId,
  allowNevativeInput,
  dataTestId,
  ...rest
}: IncreaseDecreaseProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value || "0";
    // check if val is a number
    if (!Number.isNaN(parseInt(val, 10))) {
      changeHandler(parseInt(val, 10));
    }
  };

  const onInputSubmit: React.FormEventHandler = (event) => {
    event.preventDefault();
    inputRef.current?.blur();
  };

  const increaseDecreaseInputProps: InputProps = {
    onFocus: (event: React.FocusEvent<HTMLInputElement>) => {
      event.preventDefault();
      event.currentTarget.select();
      setIsKeyboardVisible(true);
    },
    onBlur: () =>
      setTimeout(() => {
        setIsKeyboardVisible(false);
      }, 100),
    onSelect: (event: React.SyntheticEvent) => event.preventDefault(),
  };

  return (
    <IncreaseDecreaseUI
      dataTestId={dataTestId}
      onChangeHandler={onChangeHandler}
      changeHandler={changeHandler}
      allowNegativeInput={allowNevativeInput}
      ref={inputRef}
      onInputSubmit={onInputSubmit}
      inputProps={increaseDecreaseInputProps}
      label={
        <FormattedMessage id={labelMessageId} values={transformChunks({ b: { as: "strong" } })} />
      }
      isKeyboardVisible={isKeyboardVisible}
      {...rest}
    />
  );
}
