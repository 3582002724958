import { useCallback, useEffect, useState } from "react";

import { datadogRum } from "@datadog/browser-rum";
import { useNavigate } from "react-router";
import shallow from "zustand/shallow";

import { routes } from "config/routes";
import { useAppLanguageStore } from "core/stores/useAppLanguageStore";
import { countryNameFromCode } from "libs/countryNames";
import { useCustomToast } from "shared/hooks/useCustomToast";
import { serializeProductInSubcategoryWithPriceResult } from "shared/models/products/serializer";
import { TranslatedProductWithPrice } from "shared/models/products/types";
import { useGetProductsBySubcategoryV2WithPriceLazyQuery } from "shared/queries/products/products.generated";
import {
  ProductSearchActionTypes,
  useProductSearchStore,
} from "shared/stores/useProductSearchStore";

const GET_PRODUCTS_BY_SUBCATEGORY_ERROR_TOAST_ID = "get_products_by_subcategory_error_toast_id";

interface UseFetchProductsBySubcategory {
  products: TranslatedProductWithPrice[] | null;
  isLoading: boolean;
}

export function useFetchProductsBySubcategory() {
  const appLanguage = useAppLanguageStore((state) => state.appLanguage);
  const navigate = useNavigate();
  const { showToastUI } = useCustomToast();
  const { selectedSubcategoryID, setSearchState } = useProductSearchStore(
    (state) => ({
      selectedSubcategoryID: state.selectedSubcategoryID,
      setSearchState: state.setSearchState,
    }),
    shallow,
  );

  const [state, setState] = useState<UseFetchProductsBySubcategory>({
    products: null,
    isLoading: false,
  });

  const handleFetchError = useCallback(
    (error: unknown) => {
      const errorMessage = error instanceof Error ? error.message : "Unknown error";
      datadogRum.addError("Error while fetching products by subcategory", { errorMessage });
      showToastUI({
        id: GET_PRODUCTS_BY_SUBCATEGORY_ERROR_TOAST_ID,
        title: "get_products_by_subcategory_error_toast_id",
      });
      setSearchState(ProductSearchActionTypes.ACTIVATE_CATEGORY_SEARCH);
      navigate(routes.inventory.tooGoodToGo.selectProduct);
    },
    [navigate, setSearchState, showToastUI],
  );

  const [getProductsBySubcategory] = useGetProductsBySubcategoryV2WithPriceLazyQuery({
    fetchPolicy: "no-cache",
    onError: handleFetchError,
  });

  const fetchProductsBySubcategory = useCallback(async () => {
    if (selectedSubcategoryID) {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      try {
        const { data } = await getProductsBySubcategory({
          variables: {
            input: {
              subcategoryId: selectedSubcategoryID,
            },
          },
        });
        if (data) {
          const serialized = data.getProductsBySubcategoryV2.products
            .filter((product) => product.units.some((unit) => unit.type === "single"))
            .map(serializeProductInSubcategoryWithPriceResult);
          const translated = serialized?.map((product) => ({
            ...product,
            countryOfOriginName:
              countryNameFromCode(appLanguage, product.countryOfOriginCode) ?? "",
          }));
          setState((prevState) => ({ ...prevState, products: translated }));
        } else {
          setState((prevState) => ({ ...prevState, products: null }));
        }
      } catch (error) {
        handleFetchError(error);
      } finally {
        setState((prevState) => ({ ...prevState, isLoading: false }));
      }
    }
  }, [appLanguage, getProductsBySubcategory, handleFetchError, selectedSubcategoryID]);

  useEffect(() => {
    fetchProductsBySubcategory();
  }, [fetchProductsBySubcategory]);

  return state;
}
