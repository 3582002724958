import create from "zustand";

import { TranslatedProductWithPrice } from "shared/models/products/types";
import { isNullOrUndefined } from "utils/tsHelpers";

type ProductWithQuantity = TranslatedProductWithPrice & {
  quantity: number;
};

type TGTGBagStore = {
  bagKey: string | null;
  price: number | null;
  totalPrice: number;
  productsMap: Record<string, ProductWithQuantity>;
  products: string[];
};

type TGTGBagStoreWithMethods = TGTGBagStore & {
  setSelectedBag(bagKey: string | null, price: number | null): void;
  addProduct(newProduct: TranslatedProductWithPrice): void;
  increaseQuantity(sku: string): void;
  decreaseQuantity(sku: string): void;
  reset: () => void;
};

const initialState: TGTGBagStore = {
  bagKey: null,
  price: null,
  totalPrice: 0,
  productsMap: {},
  products: [],
};

export const useTGTGBagStore = create<TGTGBagStoreWithMethods>((set) => ({
  ...initialState,
  setSelectedBag: (bagKey, price) => set({ bagKey, price }),
  addProduct: (newProduct) =>
    set((state) => {
      const { productPrice } = newProduct;
      // Only proceed if productPrice is valid (not null or undefined) for MVP
      if (isNullOrUndefined(productPrice)) return state;
      const sku = newProduct.productSku;
      const existingProduct = state.productsMap[sku];
      const updatedProductsMap = {
        ...state.productsMap,
        [sku]: {
          ...newProduct,
          quantity: existingProduct ? existingProduct.quantity + 1 : 1,
        },
      };
      const updatedProducts = existingProduct ? state.products : [...state.products, sku];
      const newTotalPrice = Math.round((state.totalPrice + (productPrice ?? 0)) * 100) / 100;
      return {
        ...state,
        productsMap: updatedProductsMap,
        products: updatedProducts,
        totalPrice: newTotalPrice,
      };
    }),
  increaseQuantity: (sku) =>
    set((state) => {
      const product = state.productsMap[sku];
      if (!product) return state;
      const newTotalPrice =
        Math.round((state.totalPrice + (product.productPrice ?? 0)) * 100) / 100;
      const updatedProductsMap = { ...product, quantity: product.quantity + 1 };
      return {
        ...state,
        productsMap: { ...state.productsMap, [sku]: updatedProductsMap },
        totalPrice: newTotalPrice,
      };
    }),
  decreaseQuantity: (sku) =>
    set((state) => {
      const product = state.productsMap[sku];
      if (!product) return state;
      const newTotalPrice =
        Math.round((state.totalPrice - (product.productPrice ?? 0)) * 100) / 100;
      if (product.quantity === 1) {
        const { [sku]: removedProduct, ...updatedProductsMap } = state.productsMap;
        const updatedProducts = state.products.filter((id) => id !== sku);
        return {
          ...state,
          productsMap: updatedProductsMap,
          products: updatedProducts,
          totalPrice: newTotalPrice,
        };
      }
      const updatedProductsMap = { ...product, quantity: product.quantity - 1 };
      return {
        ...state,
        productsMap: { ...state.productsMap, [sku]: updatedProductsMap },
        totalPrice: newTotalPrice,
      };
    }),
  reset: () => set(initialState),
}));
