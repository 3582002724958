import { useCallback } from "react";

import { datadogRum } from "@datadog/browser-rum";
import { useNavigate } from "react-router";

import { InboundingListProduct, ListProductStatus } from "__graphql__/types";
import { routes } from "config/routes";
import { useCustomToast } from "shared/hooks/useCustomToast";
import { useSerializedProduct } from "shared/stores/useProductSearchService";

import { useGetProductsLazyQuery } from "../queries/collaborativeInbound/collaborativeInbound.generated";
import { TranslatedProduct } from "./inboundStore/types";
import { useInboundStore } from "./inboundStore/useInboundStore";

const GET_PRODUCTS_BY_SKUS_ERROR_TOAST_ID = "get_products_by_skus_error_toast_id";

export function useInboundPreviewService() {
  const navigate = useNavigate();
  const { showToastUI } = useCustomToast();
  const serializeProduct = useSerializedProduct();
  const setInboundPreviewProducts = useInboundStore((state) => state.setInboundPreviewProducts);

  const handleError = useCallback(
    (error: unknown) => {
      const errorMessage = error instanceof Error ? error.message : "Unknown error occurred.";
      datadogRum.addError("Error while fetching products by SKUs.", { errorMessage });
      showToastUI({
        id: GET_PRODUCTS_BY_SKUS_ERROR_TOAST_ID,
        title: "get_products_by_skus_error_toast_id",
      });
    },
    [showToastUI],
  );

  const [fetchProducts, { loading: isFetchingProducts }] = useGetProductsLazyQuery({
    fetchPolicy: "no-cache",
    onError: handleError,
  });

  const fetchInboundPreviewProducts = useCallback(
    async (products?: InboundingListProduct[] | null) => {
      if (!products) return;
      const skus = products.map((product) => product.sku);
      if (!skus) return;

      try {
        const { data } = await fetchProducts({
          variables: { input: { skus }, isAlwaysInbound: true },
        });
        const inboundingMap = new Map(products.map((p) => [p.sku, p]));
        const sortedProducts = (data?.getProducts.products || []).reduce(
          (
            acc: {
              otherProducts: TranslatedProduct[];
              inboundedProducts: TranslatedProduct[];
            },
            product,
          ) => {
            const inboundingProduct = inboundingMap.get(product.sku);
            const serializedProduct = serializeProduct(product, inboundingProduct);
            if (serializedProduct.status === ListProductStatus.list_product_status_inbounded) {
              acc.inboundedProducts.push(serializedProduct);
            } else {
              acc.otherProducts.push(serializedProduct);
            }
            return acc;
          },
          { otherProducts: [], inboundedProducts: [] },
        );

        setInboundPreviewProducts([
          ...sortedProducts.otherProducts,
          ...sortedProducts.inboundedProducts,
        ]);

        if (data) {
          navigate(routes.inbound.previewProducts);
        }
      } catch (error) {
        handleError(error);
      }
    },
    [fetchProducts, handleError, navigate, serializeProduct, setInboundPreviewProducts],
  );

  return { fetchInboundPreviewProducts, isFetchingProducts };
}
