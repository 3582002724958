import { useMemo, useState } from "react";

import { Box, Flex } from "@chakra-ui/react";
import { useIntl } from "react-intl";

import { useCustomToast } from "shared/hooks/useCustomToast";
import { useScan } from "shared/hooks/useScan/useScan";
import { useScrollToActiveItem } from "shared/hooks/useScrollToActiveItem";
import { isNullOrUndefined } from "utils/tsHelpers";

import { useInboundStore } from "../stores/inboundStore/useInboundStore";
import { InboundDroppingListItem } from "./InboundDroppingListItem";
import { InboundEndDroppingButton } from "./InboundEndDroppingButton";

type InboundDroppingListProps = {
  activeItemId: string | null;
  droppingListInboundUnitsSkusSortedByShelf: string[];
  handleTapOnCardBanner: () => void;
};

export function InboundDroppingList({
  activeItemId,
  droppingListInboundUnitsSkusSortedByShelf,
  handleTapOnCardBanner,
}: InboundDroppingListProps) {
  const intl = useIntl();
  const { showToastUI } = useCustomToast();
  const translatedLabels = useMemo(
    () => ({
      singleUnitsLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.single-units-label",
      }),
      handlingUnitsLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.handling-units-label",
      }),
      inboundedLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.inbounded-label",
      }),
      totalUnitsLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.total-handling-units-label",
      }),
      bioLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.bio-label",
      }),
      nonBioLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.non-bio-label",
      }),
      outboundedLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.outbounded-label",
      }),
      outboundLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.outbound-label",
      }),
      droppingLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.dropping-label",
      }),
      inStockLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.in-stock-label",
      }),
    }),
    [intl],
  );
  const isDroppingComplete = isNullOrUndefined(activeItemId);
  const claimedProductsSortedByShelf = useInboundStore(
    (state) => state.claimedProductsSortedByShelf,
  );
  const [focusedItemId, setFocusedItemId] = useState<string | null>(null);
  const processScannedItem = (scannedValue: string) => {
    const foundProduct = claimedProductsSortedByShelf.find((product) =>
      product.eans.includes(scannedValue),
    );
    if (foundProduct) {
      setFocusedItemId(foundProduct.sku);
    } else {
      setFocusedItemId(activeItemId);
      showToastUI({
        id: "scanned-product-not-found",
        title: "flows.inbound.components.inbound-dropping-list.error-toast",
      });
    }
  };
  useScan({
    onScan: processScannedItem,
  });

  const { itemsRef, scrollContainerRef } = useScrollToActiveItem({
    activeItemId: focusedItemId || activeItemId,
    listItemIds: droppingListInboundUnitsSkusSortedByShelf,
  });

  return (
    <Flex
      direction="column"
      pos="relative"
      ref={scrollContainerRef}
      gap="s150"
      flex={1}
      overflowY="scroll"
      w="100%"
      pt="s200"
    >
      {droppingListInboundUnitsSkusSortedByShelf.map((sku, index) => (
        <InboundDroppingListItem
          key={sku}
          sku={sku}
          elementRef={itemsRef.current[index]}
          translatedLabels={translatedLabels}
          handleTapOnCardBanner={handleTapOnCardBanner}
          handleUnforcus={() => setFocusedItemId(null)}
        />
      ))}
      {/* Below box helps us having the EndDroppingProcessButton not overflowing bottom content */}
      <Box mb="60px" />
      {isDroppingComplete && <InboundEndDroppingButton />}
    </Flex>
  );
}
