import { Box, Flex } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

import { Button } from "ui/Button/Button";
import { BagIcon } from "ui/Icons/Icons";
import { TitleM } from "ui/Typography/Typography";

type EndPreparationButtonProps = Readonly<{
  bagOfPrice: number;
  totalPrice: number;
  onClickButton: () => void;
}>;

export function EndPreparationButton({
  bagOfPrice,
  totalPrice,
  onClickButton,
}: EndPreparationButtonProps) {
  const priceGap = totalPrice < bagOfPrice ? Math.round((bagOfPrice - totalPrice) * 100) / 100 : 0;
  const formattedPriceGap = priceGap.toFixed(2);
  const formattedPrice = totalPrice.toFixed(2);
  if (priceGap) {
    return (
      <Box w="100%" h="72px" px="s100" py="s150" pos="fixed" bottom={0} zIndex={1}>
        <Button width="full" flinkVariant="primary" size="lg" position="relative" disabled>
          <BagIcon boxSize="24px" position="absolute" left="16px" />
          <FormattedMessage
            id="pages.tgtg-bag-selection-page.end-button.progress"
            values={{ price: formattedPriceGap }}
          />
        </Button>
      </Box>
    );
  }

  return (
    <Box w="100%" h="72px" px="s100" py="s150" pos="fixed" bottom={0} zIndex={1}>
      <Button
        width="full"
        flinkVariant="primary"
        size="lg"
        onClick={onClickButton}
        display="flex"
        justifyContent="space-between"
        px="s200"
      >
        <Flex align="center" gap="s200">
          <BagIcon boxSize="24px" />
          <FormattedMessage id="pages.tgtg-bag-selection-page.end-button.complete-preparation" />
        </Flex>
        <TitleM color="white">{formattedPrice} €</TitleM>
      </Button>
    </Box>
  );
}
