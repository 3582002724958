import { Flex, Image } from "@chakra-ui/react";

import { DetailL, HeaderS } from "ui/Typography/Typography";
import { isNotNullNorUndefined } from "utils/tsHelpers";

import { useTGTGBagStore } from "../stores/useTGTGBagStore";
import { ProductCardCounter } from "./ProductCardCounter";

export function ProductInfoCard({ productSku }: { productSku: string }) {
  const { productsMap, increaseQuantity, decreaseQuantity } = useTGTGBagStore();
  const product = productsMap[productSku];
  const currentProductTotalPrice =
    Math.round((product.productPrice ?? 0) * product.quantity * 100) / 100;
  const formattedPrice = currentProductTotalPrice.toFixed(2);
  return (
    <Flex
      w="100%"
      h="96px"
      px="s100"
      gap="s200"
      alignItems="center"
      justify="flex-start"
      bg="grey.150"
    >
      <Flex
        bg="grey.100"
        borderRadius="8px"
        h="80px"
        w="80px"
        alignItems="center"
        justifyContent="center"
        flexShrink={0}
      >
        {isNotNullNorUndefined(product.productImageUrl) && (
          <Image maxHeight="100%" objectFit="contain" src={product.productImageUrl} />
        )}
      </Flex>
      <Flex direction="column" justify="space-between" flex={1} h="80px">
        <DetailL>{product.productName}</DetailL>
        <ProductCardCounter
          total={product.quantity}
          onIncrementItemQuantityClick={() => increaseQuantity(productSku)}
          onDecrementItemQuantityClick={() => decreaseQuantity(productSku)}
        />
      </Flex>
      <Flex w="80px" justify="center" align="center">
        <HeaderS>{formattedPrice}€</HeaderS>
      </Flex>
    </Flex>
  );
}
