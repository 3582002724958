import create from "zustand";

import { UnitType } from "shared/models/productSearchUnit/types";

export enum ProductSearchActionTypes {
  IDLE = "IDLE", // Represents the idle state of the app when no search or fetch is active
  ACTIVATE_SEARCH = "ACTIVATE_SEARCH", // Generic search activation (text, category, or non-scannable)
  ACTIVATE_TEXT_SEARCH = "ACTIVATE_TEXT_SEARCH", // When text search is specifically triggered
  RESOLVE_TEXT_SEARCH = "RESOLVE_TEXT_SEARCH", // When text search results are resolved
  ACTIVATE_CATEGORY_SEARCH = "ACTIVATE_CATEGORY_SEARCH", // When category/subcategory search is triggered
  ACTIVATE_NON_SCANNABLE_SEARCH = "ACTIVATE_NON_SCANNABLE_SEARCH", // When non-EAN subcategories are searched
}

type ProductSearchStore = {
  currentLetterRangeId: string;
  searchUnitType: UnitType;
  textSearch: string;
  selectedCategoryName: string | null;
  selectedSubcategoryID: string | null;
  selectedSubcategoryName: string | null;
  activeState: string;
};

type ProductSearchStoreWithMethods = ProductSearchStore & {
  setSearchState: (newState: string) => void;
  setSearchUnitType: (newSearchUnitType: UnitType) => void;
  setActivateTextSearch: () => void;
  setTextSearch: (query: string) => void;
  setCategoryName: (categoryName: string) => void;
  setCurrentLetterRangeId: (currentLetterRangeId: string) => void;
  setSelectedSubcategory: ({
    subcategoryID,
    subcategoryName,
  }: {
    subcategoryID: string;
    subcategoryName?: string;
  }) => void;
  resetTextSearch: () => void;
};

const initialState: ProductSearchStore = {
  currentLetterRangeId: "AD",
  searchUnitType: "single",
  textSearch: "",
  selectedCategoryName: null,
  selectedSubcategoryID: null,
  selectedSubcategoryName: null,
  activeState: ProductSearchActionTypes.IDLE,
};

export const useProductSearchStore = create<ProductSearchStoreWithMethods>((set) => ({
  ...initialState,
  setSearchState: (newState) => set({ activeState: newState }),
  setSearchUnitType: (newSearchUnitType) => set({ searchUnitType: newSearchUnitType }),
  setActivateTextSearch: () =>
    set({
      activeState: ProductSearchActionTypes.ACTIVATE_TEXT_SEARCH,
      textSearch: "",
      selectedCategoryName: null,
      selectedSubcategoryID: null,
    }),
  setTextSearch: (query) =>
    set({ activeState: ProductSearchActionTypes.RESOLVE_TEXT_SEARCH, textSearch: query }),
  setCategoryName: (categoryName) =>
    set({
      activeState: ProductSearchActionTypes.ACTIVATE_CATEGORY_SEARCH,
      selectedCategoryName: categoryName,
    }),
  setCurrentLetterRangeId: (currentLetterRangeId) =>
    set((state) => ({
      ...state,
      currentLetterRangeId,
    })),
  setSelectedSubcategory: ({ subcategoryID, subcategoryName }) =>
    set({
      activeState: ProductSearchActionTypes.ACTIVATE_NON_SCANNABLE_SEARCH,
      selectedSubcategoryID: subcategoryID,
      selectedSubcategoryName: subcategoryName,
    }),
  resetTextSearch: () =>
    set({
      activeState: ProductSearchActionTypes.IDLE,
      textSearch: "",
      selectedCategoryName: null,
      selectedSubcategoryID: null,
    }),
}));
