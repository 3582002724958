import {
  GetProductsBySubcategoryV2WithPriceQuery,
  SearchUnitsByEanWithPriceQuery,
  SearchUnitsByTextWithPriceQuery,
} from "shared/queries/products/products.generated";

import { ProductWithPrice } from "./types";

export function serializeProductInSubcategoryWithPriceResult(
  product: GetProductsBySubcategoryV2WithPriceQuery["getProductsBySubcategoryV2"]["products"][0],
): ProductWithPrice {
  const unit = product.units.find(({ type }) => type === "single")!;
  return {
    id: unit.id,
    ean: unit.ean ?? "",
    productSku: product.sku ?? "",
    productName: product.name ?? "",
    productImageUrl: product.imageUrl ?? "",
    productPrice: product.price ?? null,
    isBio: product.bio ?? false,
    countryOfOriginCode: product.countryOfOrigin.code?.toUpperCase() ?? null,
  };
}

export function serializeSearchUnitWithPriceResult(
  unit:
    | SearchUnitsByTextWithPriceQuery["searchUnitsByText"]["units"][0]
    | SearchUnitsByEanWithPriceQuery["searchUnitsByEan"]["units"][0],
): ProductWithPrice {
  return {
    id: unit.id,
    ean: unit.ean ?? "",
    productSku: unit.product.sku ?? "",
    productName: unit.product.name ?? "",
    productImageUrl: unit.product.imageUrl ?? "",
    productPrice: unit.product.price ?? null,
    countryOfOriginCode: unit.product.countryOfOrigin.code?.toUpperCase() ?? null,
  };
}
