import * as Types from "../../../__graphql__/types.js";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type GetProductsBySubcategoryV2WithPriceQueryVariables = Types.Exact<{
  input: Types.GetProductsBySubcategoryInput;
}>;

export type GetProductsBySubcategoryV2WithPriceQuery = {
  __typename?: "Query";
  getProductsBySubcategoryV2: {
    __typename?: "GetProductsBySubcategoryResponse";
    products: Array<{
      __typename?: "IProduct";
      sku: string;
      name: string;
      imageUrl?: string | null;
      price?: number | null;
      bio?: boolean | null;
      countryOfOrigin: { __typename?: "CountryOfOrigin"; code?: string | null };
      units: Array<{ __typename?: "Unit"; ean?: string | null; id: string; type: string }>;
    }>;
  };
};

export type SearchUnitsByEanWithPriceQueryVariables = Types.Exact<{
  searchUnitsByEanInput: Types.SearchUnitsByEanInput;
  isAlwaysInbound?: Types.InputMaybe<Types.Scalars["Boolean"]>;
}>;

export type SearchUnitsByEanWithPriceQuery = {
  __typename?: "Query";
  searchUnitsByEan: {
    __typename?: "SearchUnitsByEanResponse";
    units: Array<{
      __typename?: "Unit";
      ean?: string | null;
      id: string;
      type: string;
      product: {
        __typename?: "IProduct";
        sku: string;
        name: string;
        imageUrl?: string | null;
        price?: number | null;
        countryOfOrigin: { __typename?: "CountryOfOrigin"; code?: string | null };
      };
    }>;
  };
};

export type SearchUnitsByTextWithPriceQueryVariables = Types.Exact<{
  searchUnitsByTextInput: Types.SearchUnitsByTextInput;
}>;

export type SearchUnitsByTextWithPriceQuery = {
  __typename?: "Query";
  searchUnitsByText: {
    __typename?: "SearchUnitsByTextResponse";
    units: Array<{
      __typename?: "Unit";
      ean?: string | null;
      id: string;
      type: string;
      product: {
        __typename?: "IProduct";
        sku: string;
        name: string;
        imageUrl?: string | null;
        price?: number | null;
        countryOfOrigin: { __typename?: "CountryOfOrigin"; code?: string | null };
      };
    }>;
  };
};

export const GetProductsBySubcategoryV2WithPriceDocument = gql`
  query getProductsBySubcategoryV2WithPrice($input: GetProductsBySubcategoryInput!) {
    getProductsBySubcategoryV2(input: $input) {
      products {
        sku
        name
        imageUrl
        price
        bio
        countryOfOrigin {
          code
        }
        units {
          ean
          id
          type
        }
      }
    }
  }
`;

/**
 * __useGetProductsBySubcategoryV2WithPriceQuery__
 *
 * To run a query within a React component, call `useGetProductsBySubcategoryV2WithPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsBySubcategoryV2WithPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsBySubcategoryV2WithPriceQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetProductsBySubcategoryV2WithPriceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProductsBySubcategoryV2WithPriceQuery,
    GetProductsBySubcategoryV2WithPriceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProductsBySubcategoryV2WithPriceQuery,
    GetProductsBySubcategoryV2WithPriceQueryVariables
  >(GetProductsBySubcategoryV2WithPriceDocument, options);
}
export function useGetProductsBySubcategoryV2WithPriceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductsBySubcategoryV2WithPriceQuery,
    GetProductsBySubcategoryV2WithPriceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProductsBySubcategoryV2WithPriceQuery,
    GetProductsBySubcategoryV2WithPriceQueryVariables
  >(GetProductsBySubcategoryV2WithPriceDocument, options);
}
export type GetProductsBySubcategoryV2WithPriceQueryHookResult = ReturnType<
  typeof useGetProductsBySubcategoryV2WithPriceQuery
>;
export type GetProductsBySubcategoryV2WithPriceLazyQueryHookResult = ReturnType<
  typeof useGetProductsBySubcategoryV2WithPriceLazyQuery
>;
export type GetProductsBySubcategoryV2WithPriceQueryResult = Apollo.QueryResult<
  GetProductsBySubcategoryV2WithPriceQuery,
  GetProductsBySubcategoryV2WithPriceQueryVariables
>;
export const SearchUnitsByEanWithPriceDocument = gql`
  query searchUnitsByEanWithPrice(
    $searchUnitsByEanInput: SearchUnitsByEanInput!
    $isAlwaysInbound: Boolean
  ) {
    searchUnitsByEan(
      searchUnitsByEanInput: $searchUnitsByEanInput
      isAlwaysInbound: $isAlwaysInbound
    ) {
      units {
        ean
        id
        type
        product {
          sku
          name
          imageUrl
          price
          countryOfOrigin {
            code
          }
        }
      }
    }
  }
`;

/**
 * __useSearchUnitsByEanWithPriceQuery__
 *
 * To run a query within a React component, call `useSearchUnitsByEanWithPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUnitsByEanWithPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUnitsByEanWithPriceQuery({
 *   variables: {
 *      searchUnitsByEanInput: // value for 'searchUnitsByEanInput'
 *      isAlwaysInbound: // value for 'isAlwaysInbound'
 *   },
 * });
 */
export function useSearchUnitsByEanWithPriceQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchUnitsByEanWithPriceQuery,
    SearchUnitsByEanWithPriceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchUnitsByEanWithPriceQuery, SearchUnitsByEanWithPriceQueryVariables>(
    SearchUnitsByEanWithPriceDocument,
    options,
  );
}
export function useSearchUnitsByEanWithPriceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchUnitsByEanWithPriceQuery,
    SearchUnitsByEanWithPriceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchUnitsByEanWithPriceQuery,
    SearchUnitsByEanWithPriceQueryVariables
  >(SearchUnitsByEanWithPriceDocument, options);
}
export type SearchUnitsByEanWithPriceQueryHookResult = ReturnType<
  typeof useSearchUnitsByEanWithPriceQuery
>;
export type SearchUnitsByEanWithPriceLazyQueryHookResult = ReturnType<
  typeof useSearchUnitsByEanWithPriceLazyQuery
>;
export type SearchUnitsByEanWithPriceQueryResult = Apollo.QueryResult<
  SearchUnitsByEanWithPriceQuery,
  SearchUnitsByEanWithPriceQueryVariables
>;
export const SearchUnitsByTextWithPriceDocument = gql`
  query searchUnitsByTextWithPrice($searchUnitsByTextInput: SearchUnitsByTextInput!) {
    searchUnitsByText(searchUnitsByTextInput: $searchUnitsByTextInput) {
      units {
        ean
        id
        type
        product {
          sku
          name
          imageUrl
          price
          countryOfOrigin {
            code
          }
        }
      }
    }
  }
`;

/**
 * __useSearchUnitsByTextWithPriceQuery__
 *
 * To run a query within a React component, call `useSearchUnitsByTextWithPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUnitsByTextWithPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUnitsByTextWithPriceQuery({
 *   variables: {
 *      searchUnitsByTextInput: // value for 'searchUnitsByTextInput'
 *   },
 * });
 */
export function useSearchUnitsByTextWithPriceQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchUnitsByTextWithPriceQuery,
    SearchUnitsByTextWithPriceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchUnitsByTextWithPriceQuery, SearchUnitsByTextWithPriceQueryVariables>(
    SearchUnitsByTextWithPriceDocument,
    options,
  );
}
export function useSearchUnitsByTextWithPriceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchUnitsByTextWithPriceQuery,
    SearchUnitsByTextWithPriceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchUnitsByTextWithPriceQuery,
    SearchUnitsByTextWithPriceQueryVariables
  >(SearchUnitsByTextWithPriceDocument, options);
}
export type SearchUnitsByTextWithPriceQueryHookResult = ReturnType<
  typeof useSearchUnitsByTextWithPriceQuery
>;
export type SearchUnitsByTextWithPriceLazyQueryHookResult = ReturnType<
  typeof useSearchUnitsByTextWithPriceLazyQuery
>;
export type SearchUnitsByTextWithPriceQueryResult = Apollo.QueryResult<
  SearchUnitsByTextWithPriceQuery,
  SearchUnitsByTextWithPriceQueryVariables
>;
