import React, { ReactElement, cloneElement } from "react";

import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Circle,
  ModalCloseButton,
  ModalHeader,
} from "@chakra-ui/react";

import { Button } from "ui/Button/Button";
import { BodyM, HeaderS } from "ui/Typography/Typography";

export type YesNoModalProps = {
  onClickYes: () => void;
  onClickNo: () => void;
  title: React.ReactNode;
  body?: React.ReactNode;
  yesLabel: React.ReactNode;
  noLabel: React.ReactNode;
  isOpen: boolean;
  icon?: ReactElement;
  isGrey?: boolean;
  withCloseButton?: boolean;
  boxSize?: string;
};

export function YesNoModal({
  onClickYes,
  onClickNo,
  isOpen,
  title,
  body,
  yesLabel,
  noLabel,
  icon,
  isGrey = false,
  withCloseButton = false,
  boxSize,
}: YesNoModalProps) {
  return (
    <Modal
      trapFocus={false}
      closeOnOverlayClick={false}
      isCentered
      isOpen={isOpen}
      onClose={withCloseButton ? onClickNo : () => {}}
    >
      <ModalOverlay bg="rgba(0,0,0,0.2)" />
      <ModalContent marginX="s300">
        {withCloseButton && (
          <ModalHeader>
            <ModalCloseButton w="36px" h="36px" />
          </ModalHeader>
        )}
        <ModalBody textAlign="center" paddingY="0" paddingX="s200">
          {icon && (
            <Flex align="center" marginY="s300" justify="center">
              <Circle bg="grey.100" size="120px">
                {cloneElement(icon, { boxSize: boxSize ?? "66.67px" })}
              </Circle>
            </Flex>
          )}
          <HeaderS mt={icon ? "s200" : "s300"}>{title}</HeaderS>
          {body && (
            <BodyM mt={icon ? "s100" : "s300"} color={isGrey ? "grey.700" : undefined}>
              {body}
            </BodyM>
          )}
        </ModalBody>
        <ModalFooter mt="s200" padding="s200" flexDir="column">
          <Button
            h="54px"
            onClick={onClickYes}
            width="full"
            flinkVariant="primary"
            size="lg"
            data-testid="yes-no-modal-yes-button"
          >
            {yesLabel}
          </Button>
          <Button
            h="54px"
            mt="s100"
            onClick={onClickNo}
            width="full"
            flinkVariant="secondary"
            size="lg"
            data-testid="yes-no-modal-no-button"
          >
            {noLabel}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
