import { useCallback, useEffect, useMemo, useState } from "react";

import { Box, Flex, Image } from "@chakra-ui/react";
import { datadogRum } from "@datadog/browser-rum";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";
import shallow from "zustand/shallow";

import { routes } from "config/routes";
import { useAppLanguageStore } from "core/stores/useAppLanguageStore";
import { EndDroppingProcessButton } from "flows/Inbound/components/EndDroppingProcessButton";
import { countryNameFromCode } from "libs/countryNames";
import { SpinnerModal } from "shared/components/SpinnerModal";
import { serializeSearchUnitWithPriceResult } from "shared/models/products/serializer";
import { TranslatedProductWithPrice } from "shared/models/products/types";
import { useSearchUnitsByTextWithPriceLazyQuery } from "shared/queries/products/products.generated";
import { useProductSearchStore } from "shared/stores/useProductSearchStore";
import { BodyS, DetailM, TitleM, TitleXS } from "ui/Typography/Typography";

import { useTGTGBagStore } from "../stores/useTGTGBagStore";

interface SelectedStates {
  [key: string]: boolean;
}

function NoResultsFound() {
  return (
    <Flex direction="column" flex={1} justifyContent="center" textAlign="center" px="s100">
      <TitleM>
        <FormattedMessage id="generic.warnings.no-results-found" />
      </TitleM>
      <BodyS>
        <FormattedMessage id="generic.info.no-results-found" />
      </BodyS>
    </Flex>
  );
}

function ProductCard({
  result,
  selectedStates,
  toggleSelection,
}: {
  result: TranslatedProductWithPrice;
  selectedStates: SelectedStates;
  toggleSelection: (id: string) => void;
}) {
  const { productImageUrl, productName, countryOfOriginName, productSku, id } = result;
  return (
    <Flex
      onClick={() => toggleSelection(id)}
      alignItems="center"
      p="s200"
      pos="relative"
      bg="white"
    >
      <Box
        pos="absolute"
        bg="grey.800"
        top="0"
        left="0"
        height="100%"
        width="100%"
        transition="opacity 50ms linear"
        opacity={selectedStates[id] ? 0.1 : 0}
      />
      <Flex
        bg="grey.100"
        borderRadius="8px"
        h="80px"
        w="80px"
        alignItems="center"
        justifyContent="center"
        flexShrink={0}
      >
        {productImageUrl && <Image maxHeight="100%" objectFit="contain" src={productImageUrl} />}
      </Flex>
      <Flex direction="column" ml="s200">
        <TitleXS color="grey.800" fontWeight={700} noOfLines={2}>
          {`${productName} ${countryOfOriginName ? ` (${countryOfOriginName})` : ""}`}
        </TitleXS>
        <DetailM color="grey.600">{` SKU ${productSku}`}</DetailM>
      </Flex>
    </Flex>
  );
}

export function TextSearchProductList() {
  const appLanguage = useAppLanguageStore((state) => state.appLanguage);
  const navigate = useNavigate();

  const { textSearchQuery, resetTextSearch } = useProductSearchStore(
    (state) => ({
      textSearchQuery: state.textSearch,
      resetTextSearch: state.resetTextSearch,
    }),
    shallow,
  );

  const [searchUnitsByText, { data, loading }] = useSearchUnitsByTextWithPriceLazyQuery({
    fetchPolicy: "no-cache",
    onError: (error: unknown) => {
      const errorMessage = error instanceof Error ? error.message : "Unknown error";
      datadogRum.addError("Error While Searching units by text", { errorMessage });
    },
  });

  useEffect(() => {
    const debounceSearch = setTimeout(() => {
      if (textSearchQuery?.length >= 3) {
        searchUnitsByText({ variables: { searchUnitsByTextInput: { query: textSearchQuery } } });
      }
    }, 300);
    return () => clearTimeout(debounceSearch);
  }, [textSearchQuery, searchUnitsByText]);

  const searchResults = useMemo(() => {
    return data
      ? data.searchUnitsByText.units
          .filter((unit) => unit.type === "single")
          .map(serializeSearchUnitWithPriceResult)
      : null;
  }, [data]);

  const productResults = useMemo(() => {
    if (!searchResults) return [];

    const translatedResults = searchResults.map((unit) => ({
      ...unit,
      countryOfOriginName: countryNameFromCode(appLanguage, unit.countryOfOriginCode) ?? "",
    }));
    return Array.from(
      new Map(translatedResults.map((product) => [product.productSku, product])).values(),
    );
  }, [appLanguage, searchResults]);

  const noResults = useMemo(
    () => textSearchQuery.length > 2 && productResults.length === 0,
    [textSearchQuery.length, productResults],
  );

  const [selectedStates, setSelectedStates] = useState<SelectedStates>({});

  const selectedResults = useMemo(
    () => productResults.filter(({ id }) => selectedStates[id]),
    [selectedStates, productResults],
  );

  const toggleSelection = useCallback((id: string) => {
    setSelectedStates((prevState) => ({ ...prevState, [id]: !prevState[id] }));
  }, []);
  const addProduct = useTGTGBagStore((state) => state.addProduct);
  const handleAddToList = useCallback(() => {
    selectedResults.forEach((result) => {
      addProduct(result);
    });
    resetTextSearch();
    navigate(routes.inventory.tooGoodToGo.prepareBag);
  }, [addProduct, navigate, resetTextSearch, selectedResults]);

  if (loading) return <SpinnerModal isOpen />;
  if (!productResults) return null;

  return (
    <Flex
      direction="column"
      w="100%"
      gap="s50"
      bg="grey.150"
      h={noResults ? "100%" : undefined}
      overflow="scroll"
      css={{
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      {noResults ? (
        <NoResultsFound />
      ) : (
        <>
          {productResults.map((result) => (
            <ProductCard
              key={result.id}
              result={result}
              selectedStates={selectedStates}
              toggleSelection={toggleSelection}
            />
          ))}
          <Box mb="60px" />
          <EndDroppingProcessButton
            shouldShowButton={selectedResults.length > 0}
            onClickButton={handleAddToList}
            labelMessageId="generic.actions.add-to-list"
            bottom="0"
            p="s200"
          />
        </>
      )}
    </Flex>
  );
}
