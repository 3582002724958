import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { Center, Flex, FlexProps } from "@chakra-ui/react";

import { preventPropagation } from "utils/domEvents";

type ProductCardCounterProps = {
  total: number;
  onIncrementItemQuantityClick?: () => void;
  onDecrementItemQuantityClick?: () => void;
} & FlexProps;

export function ProductCardCounter({
  total,
  onIncrementItemQuantityClick,
  onDecrementItemQuantityClick,
}: ProductCardCounterProps) {
  return (
    <Flex
      w="fit-content"
      h="32px"
      alignItems="center"
      border="1px solid"
      borderRadius="sm"
      borderColor="grey.650"
      bg="white"
    >
      <Center
        w="32px"
        h="32px"
        cursor="pointer"
        onClick={preventPropagation(onDecrementItemQuantityClick)}
        data-testid="decrement-button"
      >
        <MinusIcon color="pinkFlink.500" />
      </Center>
      <Center minW="32px" h="32px" bg="pinkFlink.500" color="white" fontWeight="bold" p="s50">
        {total}
      </Center>
      <Center
        w="32px"
        h="32px"
        cursor="pointer"
        onClick={preventPropagation(onIncrementItemQuantityClick)}
        data-testid="increment-button"
      >
        <AddIcon color="pinkFlink.500" />
      </Center>
    </Flex>
  );
}
