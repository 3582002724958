import { useIntl } from "react-intl";

import { YesNoModal, YesNoModalProps } from "shared/components/YesNoModal";

export type SSCCMatchConfirmationModalProps = Pick<
  YesNoModalProps,
  "isOpen" | "onClickYes" | "onClickNo"
>;

export function SSCCMatchConfirmationModal({
  onClickYes,
  onClickNo,
  isOpen,
}: SSCCMatchConfirmationModalProps) {
  const intl = useIntl();
  return (
    <YesNoModal
      isOpen={isOpen}
      onClickYes={onClickYes}
      onClickNo={onClickNo}
      title={intl.formatMessage({
        id: "components.sscc-match-confirmation-modal.title",
      })}
      body={intl.formatMessage({
        id: "components.sscc-match-confirmation-modal.body",
      })}
      yesLabel={intl.formatMessage({
        id: "generic.actions.continue-anyway",
      })}
      noLabel={intl.formatMessage({
        id: "components.sscc-match-confirmation-modal.no",
      })}
      isGrey
    />
  );
}
