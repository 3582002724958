import React, { useCallback, useEffect, useRef, useState } from "react";

import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  chakra,
  Flex,
  FormControl,
  FormErrorMessage,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import { FormattedMessage, useIntl } from "react-intl";

import { useDeliveryCheckinService } from "flows/Activities/services/deliveryCheckInService/service";
import { useCheckinUIStore } from "flows/Activities/stores/useCheckInUIStore";
import { InputPasswordNumbersIcon, WarningRoundFilledIcon } from "ui/Icons/Icons";
import { BodyM, TitleM } from "ui/Typography/Typography";
import { isValidSSCCFormat, cleanSSCC } from "utils/scan";
import { transformChunks } from "utils/ui";

export function ManualRolliIdInput() {
  const intl = useIntl();
  const { checkScannedRolli } = useDeliveryCheckinService();
  const inputRef = useRef<HTMLInputElement>(null);
  const [hasError, setHasError] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const { shouldShowManualRolliIdInput, toggleManualRolliIdInput } = useCheckinUIStore((state) => ({
    shouldShowManualRolliIdInput: state.shouldShowManualRolliIdInput,
    toggleManualRolliIdInput: state.toggleManualRolliIdInput,
  }));

  useEffect(() => {
    const handleOnClickOutside = (e: MouseEvent | TouchEvent) => {
      e.preventDefault();
      if (!inputRef.current || inputRef.current.contains(e.target as Node)) {
        return;
      }
      toggleManualRolliIdInput();
    };

    document.addEventListener("mousedown", handleOnClickOutside);
    document.addEventListener("touchstart", handleOnClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleOnClickOutside);
      document.removeEventListener("touchstart", handleOnClickOutside);
    };
  }, [toggleManualRolliIdInput]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setHasError(false);
  };

  const handleInputReset = () => {
    setInputValue("");
    setHasError(false);
  };

  const onFormSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!isValidSSCCFormat(inputValue)) {
        setHasError(true);
      } else {
        setHasError(false);
        checkScannedRolli(cleanSSCC(inputValue));
      }
    },
    [checkScannedRolli, inputValue],
  );

  if (!shouldShowManualRolliIdInput) return null;

  return (
    <Flex
      flexDirection="column"
      justifyContent="end"
      position="fixed"
      w="100%"
      h="100%"
      bg="rgba(0,0,0,0.2)"
    >
      <Flex align="center" w="100%" p="s200" bg="grey.150" gap="10px" ref={inputRef}>
        <chakra.form w="100%" onSubmit={onFormSubmit}>
          <FormControl isInvalid={hasError}>
            <InputGroup>
              <InputLeftElement>
                <InputPasswordNumbersIcon color="grey.800" boxSize="1.5rem" />
              </InputLeftElement>
              <Input
                inputMode="numeric"
                name="rolliId"
                value={inputValue}
                onChange={handleInputChange}
                autoComplete="off"
                placeholder={intl.formatMessage({
                  id: "components.manual-rolliId-input.placeholder",
                })}
                flex="1"
                bg="white"
                size="md"
                autoFocus
                _focusVisible={{ outline: "none" }}
                data-testid="manual-rolliId-input"
              />
              {inputValue && (
                <InputRightElement>
                  <IconButton
                    variant="solid"
                    aria-label="reset"
                    icon={<CloseIcon color="grey.600" />}
                    onClick={handleInputReset}
                    fontSize="8px"
                    cursor="pointer"
                    isRound
                    color="grey.200"
                    size="xs"
                  />
                </InputRightElement>
              )}
            </InputGroup>
            <FormErrorMessage>
              {intl.formatMessage({
                id: "components.manual-rolliId-input.error",
              })}
            </FormErrorMessage>
          </FormControl>
        </chakra.form>
        {!inputValue && (
          <IconButton
            variant="unstyled"
            aria-label="close"
            icon={<CloseIcon color="black" />}
            onClick={toggleManualRolliIdInput}
            fontSize="10px"
            minW="20px"
            maxW="20px"
          />
        )}
      </Flex>
    </Flex>
  );
}

export function RolliIdBarcodeError() {
  const { toggleManualRolliIdInput, toggleRolliIdBarcodeError, hasRolliIdBarcodeError } =
    useCheckinUIStore((state) => ({
      toggleManualRolliIdInput: state.toggleManualRolliIdInput,
      toggleRolliIdBarcodeError: state.toggleRolliIdBarcodeError,
      hasRolliIdBarcodeError: state.hasRolliIdBarcodeError,
    }));

  if (!hasRolliIdBarcodeError) return null;

  return (
    <Flex alignItems="center" borderRadius="sm" px="s200" py="s150" gap="s150" bgColor="red.100">
      <WarningRoundFilledIcon color="red.500" boxSize="1.5rem" />
      <Box flexDirection="column">
        <TitleM lineHeight="1.3rem">
          <FormattedMessage id="components.manual-rolliId-input.title" />
        </TitleM>
        <BodyM lineHeight="1.3rem">
          <FormattedMessage
            id="components.manual-rolliId-input.subTitle"
            values={transformChunks({
              b: {
                as: "strong",
                color: "red.500",
                cursor: "pointer",
                textDecoration: "underline",
                onClick: toggleManualRolliIdInput,
              },
            })}
          />
        </BodyM>
      </Box>
      <IconButton
        variant="unstyled"
        aria-label="close"
        icon={<CloseIcon color="black" />}
        onClick={toggleRolliIdBarcodeError}
        fontSize="16px"
        size="xs"
      />
    </Flex>
  );
}
