/* eslint-disable no-console */
import { useState, useEffect, useCallback } from "react";

import { UserTrackingServiceApi, V1ExperimentLookupRequest, V1Platform } from "@flink/experiment";

import { config } from "config";
import { EppoFeatureFlagsWithJson } from "core/types/flags";

const fetchFeatureFlagVariation = async (
  hubSlug: string,
  featureFlag: EppoFeatureFlagsWithJson,
): Promise<any> => {
  const userTrackingServiceApi = new UserTrackingServiceApi({}, config.environment.EPPO_URL);
  try {
    const experimentRequest: V1ExperimentLookupRequest = {
      userId: "not-used",
      meta: {
        attributes: {
          hub_slug: hubSlug,
        },
      },
      flags: [featureFlag],
      platform: V1Platform.EppoOps,
    };
    const experimentResponse = await userTrackingServiceApi.userTrackingServiceExperimentLookup(
      experimentRequest,
    );
    const decision = experimentResponse.data.decisions?.[featureFlag];
    const variation = decision?.variation;
    return variation ? JSON.parse(variation) : null;
  } catch (error: any) {
    console.error(`Error checking feature flag - ${featureFlag}:`, error.response.data);
    return null;
  }
};

export const useFeatureFlagVariation = (
  hubSlug: string,
  featureFlag: EppoFeatureFlagsWithJson,
): Record<string, unknown> | null => {
  const [variationData, setVariationData] = useState<any>(null);
  const checkFeatureFlag = useCallback(async () => {
    const decision = await fetchFeatureFlagVariation(hubSlug, featureFlag);
    setVariationData(decision);
  }, [hubSlug, featureFlag]);

  useEffect(() => {
    checkFeatureFlag();
  }, [checkFeatureFlag]);

  return variationData;
};
