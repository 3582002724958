import { useCallback, useRef, useState } from "react";

import { chakra, Flex, FormControl, FormErrorMessage, Input, InputGroup } from "@chakra-ui/react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router";
import shallow from "zustand/shallow";

import { routes } from "config/routes";
import { HEIGHT } from "core/components/Header";
import { DespatchAdviceNotFoundModal } from "flows/Inbound/components/DespatchAdviceNotFoundModal";
import { SSCCMatchConfirmationModal } from "flows/Inbound/components/SSCCMatchConfirmationModal";
import { useInboundStore } from "flows/Inbound/stores/inboundStore/useInboundStore";
import { useSharedListCreator } from "flows/Inbound/stores/useSharedListCreator";
import { Page } from "shared/components/Page";
import { useCustomToast } from "shared/hooks/useCustomToast";
import { useScan } from "shared/hooks/useScan/useScan";
import { ScanningFailedIcon } from "ui/Icons/Icons";
import { NavigationHeader } from "ui/NavigationHeader/NavigationHeader";
import { PagePlaceholder } from "ui/PagePlaceholder/PagePlaceholder";
import { Spinner } from "ui/Spinner/Spinner";
import { BodyS, DetailL } from "ui/Typography/Typography";
import { isValidSSCCFormat, cleanSSCC } from "utils/scan";
import { transformChunks } from "utils/ui";

const INVALID_ROLLI_FORMAT_TOAST_ID = "invalid_rolli_format_toast";

export function ScanRolliPage() {
  const intl = useIntl();
  const [hasError, setHasError] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { showToastUI } = useCustomToast();
  const navigate = useNavigate();

  const { deliveryType, inboundUIState, setDeliverySSCC } = useInboundStore(
    (state) => ({
      deliveryType: state.deliveryType,
      inboundUIState: state.inboundUIState,
      setDeliverySSCC: state.setDeliverySSCC,
      setInboundUIState: state.setInboundUIState,
    }),
    shallow,
  );

  const {
    isCreatingList,
    handleCreateSharedList,
    handleSSCCMatchConfirmationModalContinue,
    handleSSCCMatchConfirmationModalCancel,
    handleDespatchAdviceModalContinue,
    handleDespatchAdviceModalRetry,
  } = useSharedListCreator();

  useScan({
    onScan: (scannedValue) => {
      if (!isValidSSCCFormat(scannedValue)) {
        showToastUI({
          id: INVALID_ROLLI_FORMAT_TOAST_ID,
          title: "flows.inbound.scan-rolli.invalid-rolli-format.title",
        });
        return;
      }
      handleCreateSharedList(deliveryType, cleanSSCC(scannedValue));
    },
  });

  const handleSubmit = useCallback(
    (e?: React.FormEvent<HTMLFormElement>) => {
      e?.preventDefault();

      if (inputRef.current) {
        inputRef.current.blur();
        setIsDirty(true);

        const inputVal = inputRef.current.value;

        if (!isValidSSCCFormat(inputVal)) {
          setHasError(true);
          return;
        }
        handleCreateSharedList(deliveryType, cleanSSCC(inputVal));
      }
    },
    [deliveryType, handleCreateSharedList],
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (isDirty) {
        setHasError(!isValidSSCCFormat(e.target.value));
      }
    },
    [isDirty],
  );

  return (
    <Page isFull isBgGrey h="100%">
      <NavigationHeader
        title={intl.formatMessage({
          id: "flows.inbound.inbound-pages.header",
        })}
        onClickGoBack={() => {
          setDeliverySSCC(null);
          navigate(routes.inbound.selectDelivery);
        }}
      />
      {isCreatingList ? (
        <Flex w="100%" h="100%" align="center" justify="center">
          <Spinner />
        </Flex>
      ) : (
        <Flex w="100%" h="100%" maxH={`calc(100% - ${HEIGHT})`}>
          <Flex direction="column" align="center" w="100%" h="100%" overflowY="scroll" p="s200">
            <PagePlaceholder
              icon={<ScanningFailedIcon />}
              title={intl.formatMessage({ id: "flows.inbound.scan-rolli.placeholder-title" })}
              subtitle={
                <FormattedMessage
                  id="flows.inbound.scan-rolli.placeholder-subtitle"
                  values={transformChunks({ pink: { as: "strong", color: "pinkFlink.500" } })}
                />
              }
            />
            <chakra.form w="100%" onSubmit={handleSubmit}>
              <FormControl isInvalid={hasError}>
                <InputGroup size="lg" marginBottom="s50" marginTop="s100">
                  <Input
                    inputMode="numeric"
                    name="rolliId"
                    ref={inputRef}
                    _focusVisible={{ outline: "none" }}
                    onChange={handleChange}
                    bg="white"
                    placeholder={intl.formatMessage({
                      id: "flows.inbound.scan-rolli.input-placeholder",
                    })}
                  />
                </InputGroup>
                <FormErrorMessage bottom="-20px" left="4px">
                  <BodyS fontWeight="500" color="error">
                    <FormattedMessage id="flows.inbound.scan-rolli.input-invalid" />
                  </BodyS>
                </FormErrorMessage>
                <DetailL fontWeight="400" paddingX="s200">
                  <FormattedMessage id="flows.inbound.scan-rolli.sub-paragraph" />
                </DetailL>
              </FormControl>
            </chakra.form>
            <DespatchAdviceNotFoundModal
              isOpen={inboundUIState.isDespatchAdviceModalVisible}
              onClickContinue={handleDespatchAdviceModalContinue}
              onClickTryNew={() => {
                if (inputRef.current) inputRef.current.value = "";
                handleDespatchAdviceModalRetry();
              }}
            />
            <SSCCMatchConfirmationModal
              isOpen={inboundUIState.isSSCCMatchConfirmationModalVisible}
              onClickYes={handleSSCCMatchConfirmationModalContinue}
              onClickNo={handleSSCCMatchConfirmationModalCancel}
            />
          </Flex>
        </Flex>
      )}
    </Page>
  );
}
