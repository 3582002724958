import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Box } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";

import {
  CollaborativeInboundProgressedAction,
  CollaborativeInboundProgressedMethod,
} from "analytics/events";
import { routes } from "config/routes";
import { useLayoutStore } from "core/stores/useLayoutStore";
import { EppoFeatureFlags } from "core/types/flags";
import { useCustomToast } from "shared/hooks/useCustomToast";
import { useEppoFeatureFlagProvider } from "shared/hooks/useEppoFeatureFlag";
import useSwipeScroll from "shared/hooks/useSwipeScroll";
import { BOTTOM_TAB_BAR_HEIGHT_NUMBER } from "ui/BottomTabBarContainer";
import { OutboundIcon, TrashIcon, WarningRoundFilledIcon } from "ui/Icons/Icons";
import { InboundPreDroppingListItemCard } from "ui/InboundPreDroppingListItemCard/InboundPreDroppingListItemCard";
import { SideButton } from "ui/SideButton/SideButton";
import { SwipeScrollWrapper } from "ui/SwipeScrollWrapper";
import { Toast } from "ui/Toast";
import { isNullOrUndefined } from "utils/tsHelpers";

import { useSerialisedDespatchAdvice } from "../hooks/useDespatchAdvice/useDespatchAdvice";
import { useInboundEventTracker } from "../hooks/useInboundEventTracker/useInboundEventTracker";
import { useInboundUnitStockDataBySku } from "../hooks/useInboundUnitStockDataBySku";
import { useQuantityInputField } from "../hooks/useQuantityInputField";
import { useInboundStore } from "../stores/inboundStore/useInboundStore";
import {
  useDeleteUnclaimedProduct,
  usePreDropQuantityUpdate,
} from "../stores/useInboundPreDropService";

type PreDroppingListPreparationItemProps = {
  sku: string;
  showSwipeButtons: boolean;
  allowBlur: boolean;
  customScrollContainerRef?: React.RefObject<HTMLDivElement>;
  defaultTabBarState: boolean;
  allowEditHandlingUnitSize?: boolean;
  withDelay?: boolean;
  onSubmitQuantityChange?: () => void;
  openEditTotalQuantityModal?: (callback: () => void) => void;
};

const editHUSizeSuccessToastId = "edit-hu-size-success-toast";

export function PreDroppingListPreparationItem({
  sku,
  showSwipeButtons,
  allowBlur,
  customScrollContainerRef,
  defaultTabBarState,
  allowEditHandlingUnitSize = false,
  withDelay = false,
  onSubmitQuantityChange = () => {},
  openEditTotalQuantityModal = () => {},
}: PreDroppingListPreparationItemProps) {
  const intl = useIntl();
  const navigate = useNavigate();
  const { showToastUI } = useCustomToast();
  const { isFeatureEnabled: isCollaborativeInboundV2Enabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.COLLABORATIVE_INBOUND_V2,
  );

  const translatedLabels = useMemo(
    () => ({
      singleUnitsLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.single-units-label",
      }),
      handlingUnitsLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.handling-units-label",
      }),
      inboundedLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.inbounded-label",
      }),
      totalUnitsLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.total-handling-units-label",
      }),
      bioLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.bio-label",
      }),
      nonBioLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.non-bio-label",
      }),
      partialOutboundLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.partial-outbound-label",
      }),
      outboundedLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.outbounded-label",
      }),
      outboundLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.outbound-label",
      }),
    }),
    [intl],
  );

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const scrollContainerRefFromStore = useLayoutStore((state) => state.scrollContainerRef);
  const { scrollContainerRef, handleSwipeScroll, snapWrapperBack } = useSwipeScroll(
    customScrollContainerRef ?? scrollContainerRefFromStore,
    wrapperRef,
  );
  const { sendInboundProgressed } = useInboundEventTracker();
  const {
    sharedListId,
    inboundUnitsMap,
    despatchAdviceItems,
    skusWithUnconfirmedQuantities,
    inboundUIState,
    updateInboundQuantityBySku,
    updateHandlingUnitSize,
    confirmSkuQuantity,
    setInboundUnitIdToOutbound,
    setInboundUIState,
  } = useInboundStore((state) => ({
    sharedListId: state.sharedListId,
    inboundUnitsMap: state.inboundUnitsMap,
    despatchAdviceItems: state.despatchAdviceItems,
    skusWithUnconfirmedQuantities: state.skusWithUnconfirmedQuantities,
    inboundUIState: state.inboundUIState,
    updateInboundQuantityBySku: state.updateInboundQuantityBySku,
    updateHandlingUnitSize: state.updateHandlingUnitSize,
    confirmSkuQuantity: state.confirmSkuQuantity,
    setInboundUnitIdToOutbound: state.setInboundUnitIdToOutbound,
    setInboundUIState: state.setInboundUIState,
  }));

  const {
    skuFocusedForQuantityInput,
    isPartialOutboundAnimationVisible,
    isQuantityEditActive,
    isHUSizeEditActive,
  } = inboundUIState;

  const inboundItemDataProps = inboundUnitsMap[sku];
  const {
    displayQuantity,
    inboundQuantity,
    stockUpdatePlanTotal,
    stockUpdated,
    unitSizeForDisplay,
  } = useInboundUnitStockDataBySku(sku);

  const despatchAdviceItem = useSerialisedDespatchAdvice({
    sku,
    despatchAdviceItems,
    inboundQuantity,
  });

  const { deleteUnClaimedProduct, isDeletingUnclaimedProduct } = useDeleteUnclaimedProduct();

  const displayQuantityInContext = useMemo(() => {
    return isHUSizeEditActive ? 1 : displayQuantity;
  }, [displayQuantity, isHUSizeEditActive]);

  const displayTotalQuantityInContext = useMemo(() => {
    return isHUSizeEditActive ? unitSizeForDisplay : stockUpdatePlanTotal;
  }, [isHUSizeEditActive, stockUpdatePlanTotal, unitSizeForDisplay]);

  const elementRef = useRef<HTMLDivElement | undefined>();

  const { updatePreDropQuantity, editPreDropQuantityError, isHUUpdate } =
    usePreDropQuantityUpdate();
  const setQuantity = useCallback(
    ({ oldValue, newValue }: { oldValue: number; newValue: number }) => {
      if (isNullOrUndefined(sharedListId)) {
        return oldValue;
      }
      if (oldValue !== newValue) {
        const isQuantityMismatchBannerVisible = despatchAdviceItem
          ? despatchAdviceItem.expectedNumberOfUnits !== newValue
          : false;
        setInboundUIState({ isQuantityMismatchBannerVisible });
        if (isCollaborativeInboundV2Enabled) {
          updatePreDropQuantity({
            listId: sharedListId,
            sku,
            oldValue,
            newValue,
            method: CollaborativeInboundProgressedMethod.ManualSU,
          });
        } else {
          updateInboundQuantityBySku(sku, newValue);
          sendInboundProgressed({
            sku,
            action: CollaborativeInboundProgressedAction.ProductUpdatedQuantity,
            method: CollaborativeInboundProgressedMethod.ManualSU,
            quantityInfo: {
              newValue,
              oldValue,
            },
          });
        }
      }
      return newValue;
    },
    [
      despatchAdviceItem,
      isCollaborativeInboundV2Enabled,
      sendInboundProgressed,
      setInboundUIState,
      sharedListId,
      sku,
      updateInboundQuantityBySku,
      updatePreDropQuantity,
    ],
  );
  const setHUSize = useCallback(
    ({ oldValue, newValue }: { oldValue: number; newValue: number }) => {
      if (newValue <= 1 || isNullOrUndefined(sharedListId)) {
        return oldValue;
      }
      if (oldValue !== newValue) {
        if (isCollaborativeInboundV2Enabled) {
          updatePreDropQuantity({
            listId: sharedListId,
            sku,
            oldValue,
            newValue,
            method: CollaborativeInboundProgressedMethod.ManualHU,
          });
        } else {
          updateHandlingUnitSize(sku, newValue);
          sendInboundProgressed({
            sku,
            action: CollaborativeInboundProgressedAction.ProductUpdatedQuantity,
            method: CollaborativeInboundProgressedMethod.ManualHU,
            quantityInfo: {
              newValue,
              oldValue,
            },
          });
          showToastUI({
            id: editHUSizeSuccessToastId,
            render: ({ onClose }) => (
              <Toast
                icon={<WarningRoundFilledIcon color="white" />}
                title={intl.formatMessage({
                  id: "flows.inbound.pre-dropping.edit-total-quantity.success-toast",
                })}
                bgColor="nightBlueFlink.500"
                onClose={onClose}
                bottom={BOTTOM_TAB_BAR_HEIGHT_NUMBER}
              />
            ),
          });
        }
      }
      return newValue;
    },
    [
      intl,
      isCollaborativeInboundV2Enabled,
      sendInboundProgressed,
      sharedListId,
      showToastUI,
      sku,
      updateHandlingUnitSize,
      updatePreDropQuantity,
    ],
  );

  const onClickRemoveProduct = useCallback(() => {
    if (isDeletingUnclaimedProduct || !sharedListId) return;
    deleteUnClaimedProduct(sharedListId, sku);
    snapWrapperBack();
  }, [deleteUnClaimedProduct, isDeletingUnclaimedProduct, sharedListId, sku, snapWrapperBack]);

  const onClickOutboundButton = useCallback(() => {
    setInboundUnitIdToOutbound(sku);
    navigate(routes.inbound.outboundUnclaimed);
  }, [navigate, setInboundUnitIdToOutbound, sku]);

  const {
    fieldProps: quantityFieldProps,
    focus: focusQuantityInput,
    blur: blurQuantityInput,
    resetInputValue,
  } = useQuantityInputField({
    cardRef: elementRef,
    scrollContainerRef,
    defaultTabBarState,
    allowBlur,
    setValue: setQuantity,
    value: displayQuantity,
    onSubmit: onSubmitQuantityChange,
    applyValidationTransform: (rawValue: string) => {
      return rawValue === "" ? "1" : rawValue;
    },
  });

  const {
    fieldProps: totalQuantityFieldProps,
    focus: focusTotalQuantityInput,
    resetInputValue: resetTotalInputValue,
  } = useQuantityInputField({
    cardRef: elementRef,
    scrollContainerRef,
    defaultTabBarState,
    allowBlur,
    setValue: setHUSize,
    value: displayTotalQuantityInContext,
    uiEffects: {
      effect: () => {
        setInboundUIState({ isHUSizeEditActive: true });
        snapWrapperBack();
      },
      cleanup: () => {
        setInboundUIState({ isHUSizeEditActive: false });
      },
    },
    applyValidationTransform: (rawValue: string) => {
      return rawValue === "" || parseInt(rawValue, 10) < 2 ? null : rawValue;
    },
  });

  useEffect(() => {
    if (editPreDropQuantityError) {
      if (isHUUpdate) {
        resetTotalInputValue();
      } else {
        resetInputValue();
      }
    }
  }, [editPreDropQuantityError, isHUUpdate, resetInputValue, resetTotalInputValue]);

  const startEditingTotalQuantity = useCallback(() => {
    setTimeout(() => focusTotalQuantityInput({ preventScroll: true }));
  }, [focusTotalQuantityInput]);

  const onClickTotalQuantityNumber = useCallback(() => {
    if (!allowEditHandlingUnitSize || stockUpdated) return;
    openEditTotalQuantityModal(startEditingTotalQuantity);
  }, [
    allowEditHandlingUnitSize,
    openEditTotalQuantityModal,
    startEditingTotalQuantity,
    stockUpdated,
  ]);

  useEffect(() => {
    if (skuFocusedForQuantityInput === sku) {
      const focusAction = () => {
        focusQuantityInput({ preventScroll: true });
        setInboundUIState({ skuFocusedForQuantityInput: undefined });
      };
      setTimeout(focusAction, withDelay ? 10 : 0);
    } else if (skuFocusedForQuantityInput === null) {
      blurQuantityInput();
      setInboundUIState({ skuFocusedForQuantityInput: undefined });
    }
  }, [
    blurQuantityInput,
    focusQuantityInput,
    setInboundUIState,
    sku,
    skuFocusedForQuantityInput,
    withDelay,
  ]);

  const isOutbounded = stockUpdatePlanTotal === 0;

  const isPartiallyOutbounded = useMemo(
    () => stockUpdatePlanTotal < (inboundQuantity ?? 0) && !isOutbounded,
    [stockUpdatePlanTotal, inboundQuantity, isOutbounded],
  );

  const [showPartiallyOutboundedAnim] = useState(() => {
    return isPartialOutboundAnimationVisible === sku;
  });

  useEffect(() => {
    if (showPartiallyOutboundedAnim) {
      elementRef.current?.scrollIntoView();
      setInboundUIState({ isPartialOutboundAnimationVisible: null });
    }
  }, [showPartiallyOutboundedAnim, setInboundUIState]);

  const isQuantityConfirmed = useMemo(
    () => !skusWithUnconfirmedQuantities.includes(sku),
    [sku, skusWithUnconfirmedQuantities],
  );

  const confirmQuantity = useCallback(() => {
    confirmSkuQuantity(sku);
  }, [confirmSkuQuantity, sku]);

  const showQuantityConfirmation = useMemo(() => !!despatchAdviceItem, [despatchAdviceItem]);

  const isQuantityMatchingExpectation = useMemo(() => {
    if (!isQuantityConfirmed || !despatchAdviceItem) return null;

    return (
      despatchAdviceItem.isTotalInboundQuantityMatchingExpectation ||
      despatchAdviceItem.expectedNumberOfUnits === displayQuantity
    );
  }, [isQuantityConfirmed, despatchAdviceItem, displayQuantity]);

  const isTotalQuantityMatchingExpectation = useMemo(() => {
    if (!isQuantityConfirmed || !despatchAdviceItem || isHUSizeEditActive) return true;

    return unitSizeForDisplay === despatchAdviceItem.expectedHUSize;
  }, [isQuantityConfirmed, despatchAdviceItem, isHUSizeEditActive, unitSizeForDisplay]);

  const mainComponent = (
    <InboundPreDroppingListItemCard
      elementRef={(element) => {
        elementRef.current = element;
      }}
      stockUpdatePlanTotal={displayTotalQuantityInContext}
      {...translatedLabels}
      translatedInboundUnit={inboundItemDataProps}
      quantityFieldProps={quantityFieldProps}
      displayQuantity={displayQuantityInContext}
      isStockUpdateComplete={stockUpdated}
      displayAsHandlingUnit={unitSizeForDisplay > 1}
      isPartiallyOutbounded={isPartiallyOutbounded}
      showPartiallyOutboundedAnimation={showPartiallyOutboundedAnim}
      isOutbounded={isOutbounded}
      isEditingQuantity={isQuantityEditActive}
      isEditingTotal={isHUSizeEditActive}
      onClickTotalQuantityNumber={onClickTotalQuantityNumber}
      totalQuantityFieldProps={totalQuantityFieldProps}
      onConfirmQuantity={confirmQuantity}
      showExpectedItems={!!despatchAdviceItem}
      expectedUnitType={despatchAdviceItem?.expectedUnitType}
      expectedNumberOfUnits={despatchAdviceItem?.expectedNumberOfUnits}
      showQuantityConfirmation={showQuantityConfirmation}
      isQuantityMatchingExpectation={isQuantityMatchingExpectation}
      isTotalQuantityMatchingExpectation={isTotalQuantityMatchingExpectation}
    />
  );

  const scrolledComponent = (
    <Box flex="0 0 137px">
      <SideButton
        color="red.500"
        bgColor="red.100"
        icon={<TrashIcon boxSize={6} />}
        label={intl.formatMessage({
          id: "components.inbound.pre-dropping-list-item.delete-label",
          defaultMessage: "Delete",
        })}
        onClick={onClickRemoveProduct}
        data-testid="remove-product"
      />
      <SideButton
        color="purple.500"
        bgColor="purple.100"
        icon={<OutboundIcon boxSize={6} />}
        label={intl.formatMessage({
          id: "components.inbound.pre-dropping-list-item.outbound-label",
          defaultMessage: "Outbound",
        })}
        onClick={onClickOutboundButton}
        data-testid="go-to-inbound-outbound-page"
      />
    </Box>
  );

  return (
    <SwipeScrollWrapper
      mainComponent={mainComponent}
      handleSwipeScroll={handleSwipeScroll}
      scrolledComponent={stockUpdated && showSwipeButtons ? undefined : scrolledComponent}
      showSwipeButtons={showSwipeButtons}
      ref={wrapperRef}
    />
  );
}
