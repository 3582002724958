import { useIntl } from "react-intl";

import { YesNoModal, YesNoModalProps } from "shared/components/YesNoModal";
import { DiscardBagIcon } from "ui/Icons/Icons";

export type DiscardBagConfirmationModalProps = Pick<
  YesNoModalProps,
  "isOpen" | "onClickYes" | "onClickNo"
>;

export function DiscardBagConfirmationModal({
  onClickYes,
  onClickNo,
  isOpen,
}: DiscardBagConfirmationModalProps) {
  const intl = useIntl();
  return (
    <YesNoModal
      isOpen={isOpen}
      onClickYes={onClickYes}
      onClickNo={onClickNo}
      title={intl.formatMessage({
        id: "components.discard-bag-confirmation-modal.title",
      })}
      icon={<DiscardBagIcon />}
      body={intl.formatMessage({
        id: "components.discard-bag-confirmation-modal.body",
      })}
      yesLabel={intl.formatMessage({
        id: "components.discard-bag-confirmation-modal.yes",
      })}
      noLabel={intl.formatMessage({
        id: "generic.actions.back",
      })}
      isGrey
      withCloseButton
      boxSize="120px"
    />
  );
}
