import parseBarcode from "libs/barcodes";

type OrderQRCodeType = {
  picking_new_order: {
    id: string;
    number: string;
    is_new_customer: boolean;
    customer_email?: string;
    first_name?: string;
    last_name?: string;
  };
};

export function getOrderNumberFromQRCode(scannedValue: string) {
  let orderNumber = "";
  try {
    const parsedOrder: OrderQRCodeType = JSON.parse(scannedValue);
    if (parsedOrder?.picking_new_order?.number) {
      orderNumber = parsedOrder?.picking_new_order?.number;
    }
  } catch (e) {
    orderNumber = scannedValue.trim();
  }
  return orderNumber;
}

type ParseBarcodeOutput = {
  parsedCodeItems: {
    ai: string;
    data: string;
    dataTitle: "GTIN" | "CONTENT";
  }[];
};

export function parseGS1128Barcode(scannedValue: string): ParseBarcodeOutput {
  const result = parseBarcode(scannedValue);
  return result as ParseBarcodeOutput;
}

// returns whether {barcode} **ends** with:
// - 3-digits and a single letter (001A, 999X, etc.)
// - 3-digits, dot, digit and a single letter (001.1A, 999.1X, etc.)
export const isShelfNumberFormat = (barcode: string) => {
  const shelfNumberRegex = /\d{3}(\.\d)?[a-zA-Z]$/;
  return shelfNumberRegex.test(barcode);
};

// returns whether {barcode} is a valid rolli ID
// - standarized SSCC are 18-digit IDs
// - in NL 6-digit IDs are also valid for Boon deliveries
export const isValidSSCCFormat = (barcode: string) => {
  return /^(?:\d{6}|0{0,2}\d{18})$/.test(barcode);
};

/*
 * Return sscc trimmed without leading zeros, there are some cases when SSCC is being proceed with 18 digits and 2 zero in front
 */
export const cleanSSCC = (sscc: string) => {
  if (sscc.length > 18 && sscc.startsWith("0")) {
    return sscc.slice(-18);
  }
  return sscc;
};

export const simulateScan = (scannedValue: string) => {
  let i = 0;
  const interval = setInterval(() => {
    if (i === scannedValue.length) {
      clearInterval(interval);
      return;
    }
    window.dispatchEvent(new KeyboardEvent("keydown", { key: scannedValue[i] }));
    i += 1;
  }, 5);
};
