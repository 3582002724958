import { Heading, Text, TextProps } from "@chakra-ui/react";

export type TypographyProps = TextProps;

export const Typography = Text;

export function HeaderL({ children, ...rest }: TypographyProps) {
  return (
    <Heading
      as="h1"
      color="black"
      fontFamily="Flink-Bold"
      fontStyle="normal"
      fontWeight={700}
      fontSize="2rem"
      lineHeight="2.5rem"
      {...rest}
    >
      {children}
    </Heading>
  );
}

export function HeaderM({ children, ...rest }: TypographyProps) {
  return (
    <Heading
      as="h2"
      color="black"
      fontFamily="Flink-Bold"
      fontStyle="normal"
      fontWeight={700}
      fontSize="1.5rem"
      lineHeight="2rem"
      {...rest}
    >
      {children}
    </Heading>
  );
}

export function HeaderS({ children, ...rest }: TypographyProps) {
  return (
    <Heading
      as="h3"
      color="black"
      fontFamily="Flink-Bold"
      fontStyle="normal"
      fontWeight={700}
      fontSize="1.25rem"
      lineHeight="1.75rem"
      {...rest}
    >
      {children}
    </Heading>
  );
}

export function TitleM({ children, ...rest }: TypographyProps) {
  return (
    <Heading
      as="h4"
      color="black"
      fontFamily="Flink-Medium"
      fontStyle="normal"
      fontWeight={500}
      fontSize="1rem"
      lineHeight="1.5rem"
      {...rest}
    >
      {children}
    </Heading>
  );
}

export function TitleS({ children, ...rest }: TypographyProps) {
  return (
    <Heading
      as="h5"
      color="black"
      fontFamily="Flink-Medium"
      fontStyle="normal"
      fontWeight={500}
      fontSize="0.875rem"
      lineHeight="1.25rem"
      {...rest}
    >
      {children}
    </Heading>
  );
}

export function TitleXS({ children, ...rest }: TypographyProps) {
  return (
    <Heading
      as="h6"
      color="black"
      fontFamily="Flink-Medium"
      fontStyle="normal"
      fontWeight={500}
      fontSize="0.75rem"
      lineHeight="1rem"
      {...rest}
    >
      {children}
    </Heading>
  );
}

export function TitleXXS({ children, ...rest }: TypographyProps) {
  return (
    <Heading
      as="h6"
      color="black"
      fontFamily="Flink-Medium"
      fontStyle="normal"
      fontWeight={500}
      fontSize="0.688rem"
      lineHeight="0.875rem"
      {...rest}
    >
      {children}
    </Heading>
  );
}

export function BodyM({ children, ...rest }: TypographyProps) {
  return (
    <Text
      color="black"
      fontFamily="Flink-Regular"
      fontStyle="normal"
      fontWeight={400}
      fontSize="1rem"
      lineHeight="1.5rem"
      {...rest}
    >
      {children}
    </Text>
  );
}

export function BodyS({ children, ...rest }: TypographyProps) {
  return (
    <Text
      color="black"
      fontFamily="Flink-Regular"
      fontStyle="normal"
      fontWeight={400}
      fontSize="0.875rem"
      lineHeight="1.125rem"
      {...rest}
    >
      {children}
    </Text>
  );
}

export function DetailL({ children, ...rest }: TypographyProps) {
  return (
    <Text
      color="black"
      fontFamily="Flink-Regular"
      fontStyle="normal"
      fontWeight={400}
      fontSize="0.75rem"
      lineHeight="1rem"
      {...rest}
    >
      {children}
    </Text>
  );
}

export function DetailM({ children, ...rest }: TypographyProps) {
  return (
    <Text
      color="black"
      fontFamily="Flink-Regular"
      fontStyle="normal"
      fontWeight={400}
      fontSize="0.625rem"
      lineHeight="0.875rem"
      {...rest}
    >
      {children}
    </Text>
  );
}

export function DetailS({ children, ...rest }: TypographyProps) {
  return (
    <Text
      color="black"
      fontFamily="Flink-Regular"
      fontStyle="normal"
      fontWeight={400}
      fontSize="0.5rem"
      lineHeight="0.75rem"
      {...rest}
    >
      {children}
    </Text>
  );
}
