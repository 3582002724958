import { useCallback } from "react";

import { IconProps } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import shallow from "zustand/shallow";

import { SpinnerModal } from "shared/components/SpinnerModal";
import { useFetchNonScannableCategories } from "shared/stores/useProductSearchService";
import {
  ProductSearchActionTypes,
  useProductSearchStore,
} from "shared/stores/useProductSearchStore";
import { IntlMessageId } from "shared/types/lang";
import { ChipSelector } from "ui/ChipSelector/ChipSelector";
import {
  AppleSliceIcon,
  BeerIcon,
  BurgerAndDrinkIcon,
  CarrotIcon,
  CroissantIcon,
  DrinkIcon,
} from "ui/Icons/Icons";
import { TitleS } from "ui/Typography/Typography";

import { SubcategoriesProductsSearch } from "./SubcategoriesProductsSearch";

type CategoryOption = {
  apiName: string;
  icon: (props: IconProps) => JSX.Element;
  intlMessageId: IntlMessageId;
};

const categoryOptions: CategoryOption[] = [
  {
    apiName: "Fruits",
    icon: AppleSliceIcon,
    intlMessageId: "components.inbound.search-flow-index.fruits",
  },
  {
    apiName: "Vegetables",
    icon: CarrotIcon,
    intlMessageId: "components.inbound.search-flow-index.veggies",
  },
  {
    apiName: "Alcohol",
    icon: BeerIcon,
    intlMessageId: "components.inbound.search-flow-index.alcoholic-drinks",
  },
  {
    apiName: "Non-Alcoholic Drinks",
    icon: DrinkIcon,
    intlMessageId: "components.inbound.search-flow-index.non-alcoholic-drinks",
  },
  {
    apiName: "Bakery Products Ultra Fresh",
    icon: CroissantIcon,
    intlMessageId: "components.inbound.search-flow-index.fresh-bakery",
  },
  {
    apiName: "Ready Meals Fresh",
    icon: BurgerAndDrinkIcon,
    intlMessageId: "components.inbound.search-flow-index.ready-to-eat",
  },
];

export function CategoryProductSearch() {
  const { activeState, selectedCategoryName, setCategoryName } = useProductSearchStore(
    (state) => ({
      activeState: state.activeState,
      selectedCategoryName: state.selectedCategoryName,
      setCategoryName: state.setCategoryName,
    }),
    shallow,
  );
  const { isLoading: isSubcategoriesLoading } = useFetchNonScannableCategories();

  const selectCategory = useCallback(
    (categoryName: string) => {
      setCategoryName(categoryName);
    },
    [setCategoryName],
  );

  const shouldShowSubcategoriesList =
    !isSubcategoriesLoading && activeState === ProductSearchActionTypes.ACTIVATE_CATEGORY_SEARCH;

  return (
    <>
      <ChipSelector
        gap="s200"
        px="s200"
        pb="s200"
        overflowX="scroll"
        minHeight="56px"
        css={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
        options={categoryOptions.map((option) => ({
          value: option.apiName,
          children: (
            <>
              {option.icon({ h: "1.5rem", w: "1.5rem", me: "s50" })}
              <TitleS color="inherit">
                <FormattedMessage id={option.intlMessageId} />
              </TitleS>
            </>
          ),
          px: "s100",
        }))}
        value={selectedCategoryName}
        onInput={(v) => selectCategory(v as string)}
      />
      {isSubcategoriesLoading && <SpinnerModal isOpen />}
      {shouldShowSubcategoriesList && <SubcategoriesProductsSearch />}
    </>
  );
}
