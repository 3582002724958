import { useCallback, useEffect } from "react";

import { useNavigate } from "react-router";

import { useInboundStore } from "flows/Inbound/stores/inboundStore/useInboundStore";
import { useOutboundClaimedProduct } from "flows/Inbound/stores/useOutboundClaimedProduct";
import { isNullOrUndefined } from "utils/tsHelpers";

import { InboundOutboundPage } from "./InboundOutboundPage";

export function OutboundClaimedProduct() {
  const navigate = useNavigate();

  const { outboundSku, claimedProductsMap, claimedProductsStockUpdates } = useInboundStore(
    (state) => ({
      outboundSku: state.outboundClaimedSku,
      claimedProductsMap: state.claimedProductsMap,
      claimedProductsStockUpdates: state.claimedProductsStockUpdates,
    }),
  );
  const { updateStock, isStockUpdating } = useOutboundClaimedProduct();
  const inboundUnitData = claimedProductsMap[outboundSku || ""];
  const isInvalidParams = isNullOrUndefined(outboundSku) || isNullOrUndefined(inboundUnitData);

  const returnToInboundHomePage = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    if (isInvalidParams) {
      returnToInboundHomePage();
    }
  }, [isInvalidParams, returnToInboundHomePage]);

  if (isInvalidParams) {
    return null;
  }

  const { shelfLetter, shelfNumber, productImageUrl, productName, productSku } = inboundUnitData;
  const inboundUnitStockUpdatePlan =
    claimedProductsStockUpdates[outboundSku || ""]?.stockUpdatePlan;

  return (
    <InboundOutboundPage
      outboundSku={outboundSku}
      shelfLetter={shelfLetter}
      shelfNumber={shelfNumber}
      productSku={productSku}
      productName={productName}
      productImageUrl={productImageUrl}
      stockUpdatePlan={inboundUnitStockUpdatePlan}
      isStockUpdating={isStockUpdating}
      updateStock={updateStock}
      returnToInboundHomePage={returnToInboundHomePage}
    />
  );
}
